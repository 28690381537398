import { useState, useEffect, useRef } from "react";
import Button from "./Button.js";
import Icon from "./Icon.js";
import Gallery from "./Gallery.js";

const Form = ({
  formFields,
  formButtons,
  onSubmit,
  onChange,
  onCancel,
  changePWVisibility,
}) => {
  const ref = useRef(null);
  const [uploadedFiles, setFiles] = useState([]);
  const [disabled, toggleDisabled] = useState(true);
  const [showGallery, toggleGallery] = useState(false);
  const [mainImgIndex, setMainImgIndex] = useState(2);
  const [galleryImages, setGalleryImages] = useState([]);

  const [errors, handleErrors] = useState({
    email: { has_error: false, message: "" },
    password: { has_error: false, message: "" },
    name: { has_error: false, message: "" },
    comment: { has_error: false, message: "" },
    text: { has_error: false, message: "" },
    currentPassword: { has_error: false, message: "" },
    newPassword: { has_error: false, message: "" },
    newPasswordConfirm: { has_error: false, message: "" },
  });

  useEffect(() => {
    //Enable confirm btn if the given default values in valid status
    checkFieldsValidations();
    const fileUpload = formFields.find((f) => f.field === "fileUpload");
    if (fileUpload && fileUpload.value) {
      setFiles([...fileUpload.value]);
      setGalleryImages([...fileUpload.value.map((f) => f.url)]);
    }
  }, [formFields]);

  const handleChange = (e) => {
    formFields.find((f) => f.key === e.target.name).value = e.target.value;
    const input = e.target.name;
    const validInput = onChange(input, e.target.value);
    if (validInput.status === "KO") {
      let new_error = { ...errors };
      new_error[input] = { has_error: true, message: validInput.message };
      handleErrors(new_error);
    } else {
      let new_error = { ...errors };
      new_error[input] = { has_error: false, message: "" };
      handleErrors(new_error);
    }
    checkFieldsValidations();
  };

  const checkFieldsValidations = () => {
    const fieldsValid = formFields
      .map((f) => onChange(f.key, f.value))
      .every((valid) => valid.status === "OK");

    if (fieldsValid) {
      let new_error = { ...errors };
      Object.values(new_error).map((err) => {
        err.has_error = false;
        err.message = "";
      });
      handleErrors(new_error);
    }
    toggleDisabled(!fieldsValid);
  };

  const handleUpload = (e) => {
    const newFiles = [...e.target.files];
    if (newFiles.length > 0) {
      setGalleryImages([
        ...galleryImages,
        ...newFiles.map((file) => URL.createObjectURL(file)),
      ]);
      setFiles((files) => [...files, ...newFiles]);
    }
  };

  const removeFile = (e) => {
    const newFiles = [...uploadedFiles];
    newFiles.splice(e, 1);
    ref.current.value = ""; // allow user to upload same image after removing it
    setFiles(newFiles);
    const newImages = [...galleryImages];
    newImages.splice(e, 1);
    setGalleryImages(newImages);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const values = {};
    formFields.forEach((field) => (values[field.key] = field.value));
    const fileUpload = formFields.find(
      (formField) => formField.field === "fileUpload"
    );
    if (fileUpload) {
      values[fileUpload.key] = [...uploadedFiles];
    }
    onSubmit(values);
  };

  const handleCancel = (e) => {
    e.preventDefault();
    onCancel();
  };

  return (
    <div className="form-wrapper">
      <form className="form-container" onSubmit={handleSubmit}>
        {formFields.map((formField, i) => {
          if (formField.field === "textfield") {
            return (
              <div
                className={`field-container ${
                  formField.disabled ? "disabled" : ""
                }`}
                key={i}
              >
                <label className="label">{formField.label}</label>
                <div
                  id={formField.id}
                  className={`input-field ${
                    errors[formField.key].has_error ? "error" : ""
                  }`}
                >
                  <input
                    name={formField.key}
                    type={formField.type}
                    disabled={formField.disabled}
                    defaultValue={formField.value}
                    placeholder={formField.placeholder}
                    onChange={handleChange}
                  />
                  {formField.visibilityIcon && (
                    <Icon
                      icon={
                        formField.type === "password"
                          ? "visibilityOff"
                          : "visibility"
                      }
                      width="22"
                      height={formField.type === "password" ? "19" : "15"}
                      onClick={() =>
                        changePWVisibility(formField.key, formField.type)
                      }
                    />
                  )}
                </div>
                {
                  <p
                    style={{
                      display:
                        formField.error || errors[formField.key].has_error,
                    }}
                    className={formField.key + " error-label"}
                  >
                    {formField.error
                      ? formField.error
                      : errors[formField.key].message}
                  </p>
                }
              </div>
            );
          } else if (formField.field === "textarea") {
            return (
              <div className="field-container" key={i}>
                <label>{formField.label}</label>
                <div className="input-field">
                  <textarea
                    name={formField.key}
                    defaultValue={formField.value}
                    placeholder={formField.placeholder}
                    onChange={handleChange}
                  ></textarea>
                </div>
              </div>
            );
          } else if (formField.field === "fileUpload") {
            return (
              <div className="field-container" key={i}>
                <div className="file-upload-field">
                  <div className="uploaded-files">
                    {uploadedFiles.map((file, i) => {
                      return (
                        <div className="uploaded-file-container" key={i}>
                          <div
                            className="uploaded-file"
                            onClick={() => {
                              setMainImgIndex(i);
                              toggleGallery(true);
                            }}
                          >
                            <Icon
                              icon="imagesMode"
                              containerSize="16"
                              color="#00A5B5"
                              width="13"
                              height="13"
                            />
                            <p className="file-name">{file?.name}</p>
                          </div>
                          <Icon
                            icon="close"
                            containerSize="16"
                            width="13"
                            height="13"
                            color="#E3010F"
                            onClick={() => removeFile(i)}
                          />
                        </div>
                      );
                    })}
                  </div>
                  <div className="attach-file">
                    <Icon
                      icon="attachFile"
                      width="11"
                      height="21"
                      onClick={() => ref.current.click()}
                    />
                    <label
                      htmlFor="file-upload"
                      className="custom-uplaod-label"
                    >
                      {formField.label}
                    </label>
                    <input
                      id="file-upload"
                      type="file"
                      multiple
                      onChange={handleUpload}
                      ref={ref}
                      accept="image/jpg, image.jpeg, image/png"
                    />
                  </div>
                </div>
              </div>
            );
          }
        })}
      </form>
      <div className="form-buttons">
        {formButtons != null &&
          formButtons.map((btn, i) => {
            return (
              <Button
                id="button-form"
                key={i}
                appearance={btn.appearance}
                color={btn.color}
                text={btn.text}
                width={btn.width}
                type={btn.type === "confirm" ? "submit" : "button"}
                disabled={btn.type === "confirm" ? disabled : false}
                iconLeft={btn.iconLeft}
                iconRight={btn.iconRight}
                onClick={btn.type === "confirm" ? handleSubmit : handleCancel}
              />
            );
          })}
      </div>
      {showGallery && (
        <Gallery
          images={galleryImages}
          mainImg={mainImgIndex}
          close={() => toggleGallery(false)}
        />
      )}
    </div>
  );
};

export default Form;
