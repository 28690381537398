import { useEffect, useState } from "react";
import loader from "../assets/bottle-loader.gif";

const Loader = ({ loading }) => {
  const body = document.querySelector("body");
  const [showLoading, toggleLoading] = useState(false);

  useEffect(() => {
    body.style.overflow = loading ? "hidden" : "auto";
    body.style.pointerEvents = loading ? "none" : "auto";
    toggleLoading(loading);

    return () => {
      body.style.overflow = "auto";
      body.style.pointerEvents = "auto";
    };
  }, [loading]);

  return (
    <div className="loader" style={{ display: loading ? "flex" : "none" }}>
      <div
        className={"gif-container"}
        style={{
          transform: showLoading ? "translateY(0%)" : "translateY(200%)",
        }}
      >
        <img className="loader-gif" src={loader} />
        <p className="text">Generando escena...</p>
      </div>
    </div>
  );
};

export default Loader;
