const Button = ({
  type = "button",
  appearance = "filled",
  className = "",
  width = 146,
  color,
  text,
  disabled,
  id,
  iconLeft,
  iconRight,
  onClick,
}) => {
  return (
    <button
      id={id}
      type={type}
      className={className + " btn " + appearance + " " + color}
      style={{ width: width === "auto" ? "auto" : width + "px" }}
      disabled={disabled}
      onClick={onClick}
    >
      {iconLeft} <span className="text">{text}</span> {iconRight}
    </button>
  );
};

export default Button;
