import { useParams, useNavigate, useLocation, Link } from "react-router-dom";
import { React, useEffect, useState, useRef, useContext } from "react";
import { useContainerHeight } from "../shared/utils.js";

import Loader from "../components/Loader.js";
import Button from "../components/Button.js";
import Icon from "../components/Icon.js";

import HotspotConfiguration from "../components/HotspotConfiguration.js";
import Modal from "../components/Modal.js";

import ModelSidebar from "../components/ModelSidebar.js";
import Selectbox from "../components/Selectbox.js";
import { positionArray, properties } from "../assets/constants.js";
import Logo from "../assets/b-ar-logo.svg";
import { AppContext } from "../AppContext.js";

import "@google/model-viewer/dist/model-viewer";

const ModelViewer = () => {
  const sidebarRef = useRef(null);
  const sidebarArrowRef = useRef(null);

  const { type, subtype, project, config, element } = useParams(); //subtype var gets undefined if doesnt have subtype

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [state, setState] = useState({ elements: [] });
  const [canActivateAR, setCanActivateAR] = useState(false);
  const [showArIcons, toggleArIcons] = useState(true);
  const [loading, setLoading] = useState(false);
  const [showImgDownloadModal, toggleImgDownloadModal] = useState(false);
  const [showSideBar, toggleSideBar] = useState(false);
  const [selectedType, selectType] = useState(undefined);
  const [showConfigurationView, toggleConfigurationView] = useState(false);
  const [configEnabled, enableConfigButton] = useState(false);
  const [actualElement, updateActualElement] = useState(undefined);
  // FILTERS
  const [brands, updateBrands] = useState([]);
  const [textures, updateTextures] = useState([]);
  const [kinds, updateKinds] = useState([]);
  const [textures2, updateTextures2] = useState([]);
  const [kinds2, updateKinds2] = useState([]);
  const [subtypes, updateSubtypes] = useState([]);
  // SELECTED FILTERS
  const [selectedBrand, updateSelectedBrand] = useState(undefined);
  const [selectedTexture, updateSelectedTexture] = useState(undefined);
  const [selectedKind, updateSelectedKind] = useState(undefined);
  const [selectedTexture2, updateSelectedTexture2] = useState(undefined);
  const [selectedKind2, updateSelectedKind2] = useState(undefined);
  const [selectedSubtype, updateSelectedSubtype] = useState(undefined);
  const [filterError, toogleFilterError] = useState(false);
  const [new_model_route, updateModelRoute] = useState("");
  const { generateSummary, getGalleryImages } = useContext(AppContext);

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + window.localStorage.getItem("token"),
  };

  const onLoad = () => {
    setLoading(false);
  };

  const showConfig = () => {
    const modelViewer = document.querySelector("model-viewer");
    modelViewer.cameraOrbit = "45deg 75deg 100%";
    modelViewer.zoom(2);
    toggleConfigurationView(true);
    toggleSideBar(false);
  };

  const hideConfig = () => {
    const modelViewer = document.querySelector("model-viewer");
    modelViewer.cameraOrbit = "0deg 75deg 100%";
    modelViewer.zoom(-2);
    toggleConfigurationView(false);
  };

  const save = () => {
    generateSummary(actualElement);
    return navigate("/summary", {
      state: {
        previousPath: pathname,
        project,
        config,
        element: actualElement.id,
      },
    });
  };

  // const getTypeElements = async () => {
  //   const columnsTypeId = 1;
  //   const res = await getElements(columnsTypeId);
  //   setState(res);
  //   setColumnsFilter(res);
  // };

  const getSubType = async () => {
    fetch(
      `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/elements/${type}/${subtype}/element`,
      {
        method: "GET",
        headers: headers,
      },
    )
      .then(async (res) => {
        const data = await res.json();
        if (data.status === 200 && data.body.length > 0) {
          const newState = { elements: data.body.sort((a, b) => a.id - b.id) };
          setState(newState);
        } else console.log("ERROR");
      })
      .catch((err) => console.log(err));
  };

  const setNewActualElement = (updatingKey, value, isMain) => {
    let filterValues = [
      selectedBrand,
      selectedTexture,
      selectedKind,
      selectedTexture2,
      selectedKind2,
      selectedSubtype,
    ];

    if (config && element) {
      const model = state.elements.find((elements) => elements.id === +element);
      filterValues = [
        model.brand_id,
        model.texture_id,
        model.kind_id,
        model.texture2_id,
        model.kind2_id,
        subtype,
      ];
    }

    filterValues[properties.findIndex((e) => e === updatingKey)] = value;

    const checkAllFilters = (elem) => {
      return filterValues.reduce((acc, filter, i) => {
        if (
          filter &&
          Number.parseInt(elem[properties[i]]) !== Number.parseInt(filter)
        ) {
          return acc && false;
        }
        return acc && true;
      }, true);
    };

    let new_actual_element;
    if (isMain && !config && !element)
      new_actual_element = state.elements.find(
        (x) => x.subtype_id === value && x.main === isMain,
      );
    else new_actual_element = state.elements.find((x) => checkAllFilters(x));

    if (new_actual_element == undefined && updatingKey === "brand_id") {
      new_actual_element = state.elements.find(
        (x) => x.brand_id === value && x.subtype_id === subtype,
      );
    }

    //orion
    if (type === "5" && updatingKey === "texture_id") {
      new_actual_element = state.elements.find(
        (x) =>
          x.brand_id === selectedBrand &&
          x.kind_id === selectedKind &&
          x.subtype_id === subtype &&
          x.texture_id === value,
      );
    }

    if (new_actual_element != undefined)
      updateActualElement(new_actual_element);

    return new_actual_element;
  };

  const updateFilters = (new_actual_element, filter, value) => {
    let brand_data = [],
      texture_data = [],
      kind_data = [],
      texture2_data = [],
      kind2_data = [];

    let properties = [
      {
        id: "brand_id",
        picture: "brand_picture",
        name: "brand_name",
        state: brands,
        method: updateBrands,
        select: updateSelectedBrand,
        array: brand_data,
      },
      {
        id: "texture_id",
        picture: "texture_picture",
        name: "texture_name",
        state: textures,
        method: updateTextures,
        select: updateSelectedTexture,
        array: texture_data,
      },
      {
        id: "kind_id",
        picture: "kind_picture",
        name: "kind_name",
        state: kinds,
        method: updateKinds,
        select: updateSelectedKind,
        array: kind_data,
      },
      {
        id: "texture2_id",
        picture: "texture2_picture",
        name: "texture2_name",
        state: textures2,
        method: updateTextures2,
        select: updateSelectedTexture2,
        array: texture2_data,
      },
      {
        id: "kind2_id",
        picture: "kind2_picture",
        name: "kind2_name",
        state: kinds2,
        method: updateKinds2,
        select: updateSelectedKind2,
        array: kind2_data,
      },
      {
        id: "subtype_id",
        picture: "subtype_picture",
        name: "subtype_name",
        state: subtypes,
        method: updateSubtypes,
      },
    ];

    if (filter !== "subtype_id")
      properties.filter((prop) => prop.id === filter)[0].select(value); // actualizamos la prop

    properties = properties.filter((prop) => prop.id !== filter); // y actualizamos el resto de posibilidad

    const available_elements = state.elements.filter(
      (elem) =>
        Number.parseInt(elem[filter]) === Number.parseInt(value) &&
        Number.parseInt(elem.subtype_id) ===
          Number.parseInt(new_actual_element.subtype_id),
    );

    // if (properties[0].state.length !== 0) {
    //   // si tenemos marcas, no las actualizamos para no perderlas
    //   properties.shift();
    // }
    available_elements.map((e) => {
      //guardamos todos los elementos que tengan el filtro elegido
      properties = properties
        .filter((elem) => elem.id !== "subtype_id") // no queremos actualizar subtypes
        .map((prop) => {
          if (e[prop.id] != undefined && e[prop.name] != undefined)
            prop.array.push({
              id: e[prop.id],
              picture: e[prop.picture],
              name: e[prop.name],
              active: new_actual_element[prop.id] === e[prop.id],
            });
          return prop;
        });
    });

    properties.forEach((prop) => {
      // limpiamos los que estén repetidos
      prop.array = prop.array.filter(
        (v, i, a) => a.findIndex((v2) => v2.id === v.id) === i,
      );
      prop.method(prop.array);
    });

    //check how to update subtypes

    let orderedElements = state.elements.filter(function (item) {
      return item !== new_actual_element && item.main === 1;
    });

    orderedElements.unshift(new_actual_element);

    let new_subtypes = orderedElements.reduce((acc, current) => {
      if (!acc.find((item) => item.id === current.subtype_id)) {
        return acc.concat([
          {
            id: current.subtype_id,
            name: current.subtype_name,
            picture: current.subtype_picture,
          },
        ]);
      } else return acc;
    }, []);

    new_subtypes.sort((a, b) => a.name.localeCompare(b.name));
    updateSubtypes(new_subtypes);
  };

  const updateModel = (updatingKey, value, isMain) => {
    if (updatingKey === "subtype_id") updateSelectedSubtype(value);

    let new_actual_element = setNewActualElement(updatingKey, value, isMain);

    if (new_actual_element != undefined) {
      updateFilters(new_actual_element, updatingKey, value);

      const route =
        "/assets/models/" +
        new_actual_element.type_alias.toLowerCase() +
        "/" +
        new_actual_element.subtype_alias.toLowerCase() +
        "/" +
        new_actual_element.model +
        ".glb";
      updateModelRoute(route);

      setConfigEnable(new_actual_element);
      getGalleryImages(new_actual_element.subtype_id);

      updateSelectedBrand(new_actual_element.brand_id);
      updateSelectedTexture(new_actual_element.texture_id);
      updateSelectedKind(new_actual_element.kind_id);
      updateSelectedTexture2(new_actual_element.texture2_id);
      updateSelectedKind2(new_actual_element.kind2_id);

      updateFilters(new_actual_element, updatingKey, value);

      if (config && element)
        return navigate(
          `/${new_actual_element.type_id}/${new_actual_element.subtype_id}/models/${new_actual_element.name}/${config}/${element}`,
          {
            state: { elements: state.elements },
          },
        );
      return navigate(
        `/${new_actual_element.type_id}/${new_actual_element.subtype_id}/models/${new_actual_element.name}`,
        {
          state: { elements: state.elements },
        },
      );
    } else {
      if (
        type === "1" &&
        subtype === "5" &&
        (updatingKey === "texture_id" || updatingKey === "kind_id")
      ) {
        let element =
          updatingKey === "texture_id"
            ? state.elements.find(
                (x) =>
                  x.type_id === "1" &&
                  x.subtype_id === "5" &&
                  x.brand_id === selectedBrand &&
                  x.texture_id === value &&
                  x.kind_id === (value === 2 ? 11 : 12),
              )
            : state.elements.find(
                (x) =>
                  x.type_id === "1" &&
                  x.subtype_id === "5" &&
                  x.brand_id === selectedBrand &&
                  x.texture_id === 2 &&
                  x.kind_id === value,
              );

        if (element == undefined) {
          toogleFilterError(true);
          return "Filter error";
        }
        updateActualElement(element);
        setConfigEnable(element);
        getGalleryImages(element.subtype_id);
        updateSelectedBrand(element.brand_id);
        updateSelectedTexture(2);
        updateSelectedKind(
          updatingKey === "kind_id" ? value : value === 2 ? 11 : 12,
        );

        const route =
          "/assets/models/" +
          element.type_alias.toLowerCase() +
          "/" +
          element.subtype_alias.toLowerCase() +
          "/" +
          element.model +
          ".glb";

        updateModelRoute(route);
      }
      toogleFilterError(true);
      return "Filter error";
    }
  };

  const toggleShowArIcons = (status) => {
    if (status === "session-started") toggleArIcons(false);
    if (status === "not-presenting") toggleArIcons(true);
  };

  useEffect(() => {
    const modelViewer = document.querySelector("model-viewer");
    modelViewer.addEventListener("load", onLoad);
    modelViewer.addEventListener("progress", onProgress);
    modelViewer.addEventListener("ar-status", (event) => {
      toggleShowArIcons(event.detail.status);
    });

    getSubType();

    setCanActivateAR(modelViewer.canActivateAR);
  }, []);

  useEffect(() => {
    if (state.elements.length !== 0) {
      componentInit();
    }
  }, [state]);

  //Close the sidebar menu when the user clicks outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        sidebarRef.current &&
        sidebarRef.current.classList.contains("open") &&
        !sidebarRef.current.contains(event.target) &&
        !sidebarArrowRef.current.contains(event.target)
      ) {
        toggleSideBar(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [sidebarRef]);

  useContainerHeight({
    containerClassName: "model-wrapper",
  });

  const componentInit = () => {
    console.log("componentInit");
    updateSelectedSubtype(subtype);
    let new_actual_element = setNewActualElement("subtype_id", subtype, 1);
    selectType(new_actual_element);

    console.log(subtype, new_actual_element);

    const pretty_type = state.elements
      .find((x) => x.type_id === type)
      .type_alias.toLowerCase();
    const pretty_subtype =
      subtype != null
        ? state.elements
            .find((x) => x.subtype_id === subtype)
            .subtype_alias.toLowerCase()
        : "";
    const new_model_route = `/assets/models/${pretty_type}/${
      subtype ? pretty_subtype + "/" : "/"
    }${new_actual_element.model}.glb`;

    updateModelRoute(new_model_route);

    updateFilters(new_actual_element, "subtype_id", subtype);
    updateSelectedBrand(new_actual_element.brand_id);
    updateSelectedTexture(new_actual_element.texture_id);
    updateSelectedKind(new_actual_element.kind_id);
    updateSelectedTexture2(new_actual_element.texture2_id);
    updateSelectedKind2(new_actual_element.kind2_id);

    setConfigEnable(new_actual_element);
    getGalleryImages(new_actual_element.subtype_id);
  };

  const exportPNG = async () => {
    setLoading(true);
    const png = await document.querySelector("model-viewer").toDataURL();
    const link = document.createElement("a");
    link.href = png;
    link.download =
      subtypes.find((stype) => stype.id === subtype).name + ".png";
    link.click();
    setTimeout(() => {
      setLoading(false);
      toggleImgDownloadModal(true);
    }, 3500);
  };

  // On change any part of model
  const onProgress = (event) => {
    setLoading(true);
    if (event.detail.totalProgress === 1) {
      setLoading(false);
    }
  };

  const getConfigData = (array) => {
    if (actualElement == null) return [];
    return array.filter(
      (config) =>
        config.model.toUpperCase() ===
        actualElement.subtype_alias.toUpperCase(),
    );
  };

  const setConfigEnable = (new_actual_element) => {
    let positionsArray = positionArray.filter(
      (config) =>
        config.model.toUpperCase() ===
        new_actual_element.subtype_alias.toUpperCase(),
    );

    // disable if its iconic green or any enfriadores
    enableConfigButton(
      positionsArray.length !== 0 && new_actual_element.subtype_id !== "6",
    );
  };

  return (
    <main>
      <div
        className={`model-wrapper ${showConfigurationView && "config-view"}`}
      >
        <div
          className={`select-and-logo ${
            showSideBar ? "sidebar-open" : "sidebar-closed"
          }`}
        >
          <Link to="/">
            <img alt="ar-logo" src={Logo} className="ar-logo" />
          </Link>
          <Selectbox
            label={selectedType?.type_name}
            value={{
              value: actualElement?.subtype_id,
              label: actualElement?.subtype_name,
            }}
            options={subtypes}
            onChange={(e) => {
              setLoading(true);
              updateModel("subtype_id", e.value, 1);
            }}
          />
        </div>
        <ModelSidebar
          element={actualElement}
          type={selectedType?.type_name}
          subtype={subtypes.find((stype) => stype.id === subtype)?.name}
          showSidebar={showSideBar}
          toggleSidebar={() => toggleSideBar(!showSideBar)}
          ref={{ sidebarRef: sidebarRef, sidebarArrowRef: sidebarArrowRef }}
        />
        <section className={`model-background ${showSideBar ? "overlay" : ""}`}>
          <model-viewer
            src={new_model_route}
            ar=""
            ar-mode="fixed"
            align-model="origin"
            ar-modes="webxr scene-viewer quick-look"
            high-performance="low-power"
            camera-controls
            data-js-focus-visible=""
            enable-pan=""
            minimumRenderScale=""
            seamless-poster=""
            shadow-intensity="1"
            exposure="2"
            environment-image="/assets/ENVIRONMENT.png"
          >
            <div className="progress-bar hide model-viewer" slot="progress-bar">
              <div className="update-bar"></div>
            </div>
            <button
              slot="ar-button"
              className={`ar-button ${
                !canActivateAR && !showArIcons && showConfigurationView
                  ? "hide"
                  : "show"
              }`}
            >
              <Icon icon="viewInAR" width="22" height="22" color="#FFFFFF" />
            </button>
            <div
              className={`export-png-icon ${
                !showArIcons || showConfigurationView || loading
                  ? "hide"
                  : "show"
              } ${canActivateAR && "ar-active"}`}
              onClick={exportPNG}
            >
              <Icon
                icon="photoCameraFrame"
                width="26"
                height="26"
                containerSize="26"
              />
              <Icon icon="photoCamera" width="18" height="14" />
            </div>
            <div id="ar-prompt">
              <img alt="ar" src="/assets/ar_hand_prompt.png" />
            </div>
          </model-viewer>
        </section>
      </div>
      <section className="model-buttons">
        <Button
          appearance="basic"
          className={`restart-btn ${
            showSideBar ? "sidebar-open" : "sidebar-closed"
          }`}
          color="aqua"
          text="Restablecer"
          iconLeft={
            <Icon icon="restart" color="#00A5B5" width="16" height="20" />
          }
          onClick={() => {
            updateModel("subtype_id", subtype, 1);
          }}
          disabled={showConfigurationView}
        />
        <div className="configure-and-save">
          {getConfigData(positionArray).length !== 0 &&
            !showConfigurationView && (
              <Button
                appearance="filled"
                color="dark-green"
                text="Configurar"
                iconLeft={
                  <Icon
                    icon="autoFixHigh"
                    color="#FFFFFF"
                    width="20"
                    height="19"
                  />
                }
                onClick={showConfig}
                disabled={!configEnabled}
              />
            )}
          {!showConfigurationView && (
            <Button
              appearance="stroke"
              color="dark-green"
              text="Guardar"
              iconLeft={<Icon icon="bookmark" width="14" height="17" />}
              onClick={save}
            />
          )}
        </div>
      </section>
      {showConfigurationView && (
        <section className="configuration-view">
          {
            <HotspotConfiguration
              config={getConfigData(positionArray)[0]}
              data_map={{
                texture: textures,
                brand: brands.sort((a, b) => a.name.localeCompare(b.nae)),
                kind: kinds,
                texture2: textures2,
                kind2: kinds2,
              }}
              selected_map={{
                texture: selectedTexture,
                brand: selectedBrand,
                kind: selectedKind,
                texture2: selectedTexture2,
                kind2: selectedKind2,
              }}
              selectedType={selectedType}
              updateModel={updateModel}
              filterError={filterError}
              toogleFilterError={toogleFilterError}
              hideConfig={hideConfig}
            />
          }
        </section>
      )}
      <div className="img-download-modal">
        <Modal
          show={showImgDownloadModal}
          icon={
            <Icon
              icon="checkCircle"
              width="52"
              height="52"
              containerSize="52"
              color="#48A742"
              clickable={false}
            />
          }
          description="La imagen se ha guardado correctamente"
          buttons={
            <Button
              appearance="basic"
              color="aqua"
              text="Aceptar"
              width="auto"
              onClick={() => toggleImgDownloadModal(false)}
            />
          }
        />
      </div>
      <Loader loading={loading} />
    </main>
  );
};

export default ModelViewer;
