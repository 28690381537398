import { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";

import Icon from "../components/Icon.js";
import Button from "../components/Button.js";
import Form from "../components/Form.js";
import Modal from "../components/Modal.js";

import Logo from "../assets/b-ar-logo.svg";
import { AppContext } from "../AppContext.js";

const Profile = () => {
  const [formFields, setFormFields] = useState([
    {
      field: "textfield",
      id: "email",
      key: "email",
      label: "Email",
      value: "",
      type: "email",
      placeholder: "",
      disabled: true,
    },
    {
      field: "textfield",
      id: "password",
      key: "currentPassword",
      label: "Contraseña",
      value: "",
      type: "password",
      placeholder: "Introduce tu contraseña",
      visibilityIcon: true,
      error: "",
    },
  ]);
  const [formButtons, setFormButtons] = useState([
    {
      type: "confirm",
      text: "Cambiar contraseña",
      appearance: "basic",
      color: "aqua",
      width: "auto",
    },
  ]);
  const [changePWActive, setChangePWActive] = useState(false);
  const [modalVisible, setModal] = useState(false);
  const navigate = useNavigate();
  const { user, setUser } = useContext(AppContext);

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + window.localStorage.getItem("token"),
  };

  const onChange = (inputType, value) => {
    if (inputType === "currentPassword") {
      return value === "incorrect password"
        ? { status: "KO", message: "Contraseña incorrecta!" }
        : value.length >= 8
        ? { status: "OK", message: "OK" }
        : {
            status: "KO",
            message: "La contraseña tiene que tener 8 caracteres.",
          };
    } else if (
      inputType === "newPassword" ||
      inputType === "newPasswordConfirm"
    ) {
      return value.length < 8
        ? {
            status: "KO",
            message: "La contraseña tiene que tener 8 caracteres.",
          }
        : formFields.find((field) => field.key === "newPassword").value !==
          formFields.find((field) => field.key === "newPasswordConfirm").value
        ? {
            status: "KO",
            message: "La contraseña no coincide con la anterior",
          }
        : { status: "OK", message: "OK" };
    } else {
      return { status: "OK", message: "OK" };
    }
  };

  const checkPassword = async (inputs) => {
    let res = await fetch(
      `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/users/checkPassword`,
      {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
          email: inputs.email,
          password: inputs.currentPassword,
        }),
      }
    )
      .then(async (res) => {
        const data = await res.json();
        if (data.success) {
          changeFormLayout();
        } else {
          formFields.find((field) => field.key === "currentPassword").error =
            "Contraseña incorrecta!";
          setFormFields((formFields) => [...formFields]);
        }
      })
      .catch((error) => {
        console.error(error);
        navigate("/login");
      });
  };

  const changeFormLayout = () => {
    formFields.find((field) => field.key === "currentPassword").error = "";
    formFields.find((field) => field.key === "currentPassword").disabled = true;
    setFormFields((fields) => [
      ...fields,
      {
        field: "textfield",
        id: "password",
        key: "newPassword",
        label: "Nueva contraseña",
        value: "",
        type: "password",
        placeholder: "Introduce tu nueva contraseña",
        visibilityIcon: true,
      },
      {
        field: "textfield",
        id: "password",
        key: "newPasswordConfirm",
        label: "Confirma contraseña",
        value: "",
        type: "password",
        placeholder: "Repite tu nueva contraseña",
        visibilityIcon: true,
      },
    ]);
    setFormButtons([
      {
        type: "confirm",
        text: "Confirmar contraseña",
        appearance: "filled",
        color: "dark-green",
        width: "193",
      },
    ]);
    setChangePWActive(true);
    onChange("newPassword", "");
    onChange("newPasswordConfirm", "");
  };

  const confirmPasswordChange = () => {
    setModal(true);
  };

  const changePassword = async () => {
    let res = await fetch(
      `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/users/password/${user.id}`,
      {
        method: "PUT",
        headers: headers,
        body: JSON.stringify({
          new_password: formFields.find((field) => field.key === "newPassword")
            .value,
          old_password: formFields.find(
            (field) => field.key === "currentPassword"
          ).value,
        }),
      }
    );
    // sendEmail();
    setModal(false);
  };

  const signOut = async () => {
    window.localStorage.removeItem("token");
    let res = await fetch(
      `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/users/logout`,
      {
        method: "POST",
        headers: headers,
        body: JSON.stringify({}),
      }
    )
      .then(async (res) => {
        const data = await res.json();
        if (data.success) {
          setUser({});
          navigate("/login");
        } else {
        }
      })
      .catch((error) => {
        console.error(error);
        navigate("/login");
      });
  };

  useEffect(() => {
    if (user.email) {
      formFields.find((field) => field.key === "email").value = user.email;
      setFormFields((formFields) => [...formFields]);
    }
  }, [user]);

  return (
    <div className="profile-container">
      <div className="header">
        <h1>Mi cuenta</h1>
        <Button
          appearance="basic"
          color="aqua"
          text="Volver"
          width="auto"
          className="back-btn"
          onClick={() => navigate(-1)}
        />
      </div>
      <div
        className={`profile-info ${changePWActive ? "change-pw-active" : ""}`}
      >
        <div className="img-and-name">
          <img src="/assets/profile-image.png" className="profile-img" />
          <p className="name">{user.nickname}</p>
        </div>
        <Form
          formFields={formFields}
          formButtons={formButtons}
          onSubmit={changePWActive ? confirmPasswordChange : checkPassword}
          onChange={onChange}
          changePWVisibility={(key, type) => {
            formFields.find((field) => field.key === key).type =
              type === "password" ? "text" : "password";
            setFormFields((formFields) => [...formFields]);
          }}
        />
      </div>

      <Button
        appearance="filled"
        color="red"
        text="Cerrar sesión"
        className="sign-out-btn"
        iconRight={
          <Icon icon="logout" width="18" height="18" color="#FFFFFF" />
        }
        onClick={signOut}
      />

      <Modal
        show={modalVisible}
        title="Confirmar contraseña"
        description="¿Estás seguro de actualizar tu contraseña?"
        buttons={
          <>
            <Button
              text="Aceptar"
              appearance="filled"
              color="dark-green"
              width="124"
              onClick={changePassword}
            />
            <Button
              text="Conservar"
              appearance="stroke"
              color="grey"
              width="124"
              onClick={() => setModal(false)}
            />
          </>
        }
      />
    </div>
  );
};

export default Profile;
