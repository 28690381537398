import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import Icon from "../components/Icon.js";

const SubCategoryCard = ({
  id,
  image,
  name,
  setOfColumnsActive,
  addMoreColumn,
  onClick,
}) => {
  const [columnCount, setColumnCount] = useState(0);
  useEffect(() => {}, [addMoreColumn]);

  const navigate = useNavigate();

  const handleCounter = (operation) => {
    if (operation === "add") {
      setColumnCount(columnCount + 1);
    } else {
      setColumnCount(columnCount - 1);
    }
    onClick(operation);
  };

  const navigateSetColumns = () => {
    navigate("/set-of-columns");
  };
  return (
    <div
      className={`subcategory-card ${
        setOfColumnsActive && "set-of-columns-active"
      } ${columnCount === 0 && "no-sub"} ${!addMoreColumn && "no-add"}`}
      onClick={
        name === "Set de columnas"
          ? navigateSetColumns
          : !setOfColumnsActive
          ? onClick
          : undefined
      }
    >
      <img src={image} />
      <div className="name-container">
        <p className="name">{name}</p>
      </div>
      {setOfColumnsActive && (
        <div className="counter-container">
          <Icon
            icon="remove"
            width="11"
            height="1"
            color="#006227"
            containerSize="32"
            clickable={columnCount !== 0}
            onClick={() => handleCounter("remove")}
          />
          <p className="counter">{columnCount}</p>
          <Icon
            icon="add"
            width="11"
            height="11"
            color="#006227"
            containerSize="32"
            clickable={addMoreColumn}
            onClick={() => handleCounter("add")}
          />
        </div>
      )}
    </div>
  );
};

export default SubCategoryCard;
