import { Link } from "react-router-dom";
import Icon from "./Icon.js";

const CategoryCard = ({ id, image, name, count }) => {
  const link = id + "/" + name + "/subcategories";
  const desc = count + " modelos disponibles";
  return (
    <Link to={link}>
      <div className="category-card">
        <img src={image} />
        <div className="text">
          <div className="name">
            <p className="title">{name}</p>
            <p className="available-model-count">{desc}</p>
          </div>
          <button className="filled-btn">
            <Icon
              icon="arrowForward"
              width="14.45"
              height="14.45"
              color="#FFFFFF"
            />
          </button>
        </div>
      </div>
    </Link>
  );
};

export default CategoryCard;
