import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";

import Form from "../components/Form.js";
import Modal from "../components/Modal.js";
import Icon from "../components/Icon.js";
import Button from "../components/Button.js";
import Logo from "../assets/b-ar-logo.svg";

const Signup = () => {
  const [formFields, setFormFields] = useState([
    {
      field: "textfield",
      id: "email",
      key: "email",
      label: "Email",
      value: "",
      type: "email",
      placeholder: "Introduce tu email",
    },
    {
      field: "textfield",
      id: "firstname",
      key: "name",
      label: "Nombre",
      value: "",
      type: "text",
      placeholder: "Introduce tu nombre",
    },
    {
      field: "textfield",
      id: "lastname",
      key: "text",
      label: "Apellido",
      value: "",
      type: "text",
      placeholder: "Introduce tu apellido",
    },
  ]);
  const formButtons = [
    {
      type: "confirm",
      text: "Aceptar",
      appearance: "filled",
      color: "dark-green",
      width: 126,
    },
  ];
  const [emailError, showEmailError] = useState(false);
  const [isPortrait, setPortrait] = useState(false);
  const [modalVisible, setModal] = useState(false);
  const navigate = useNavigate();

  const checkOrientation = () => {
    setPortrait(window.innerHeight > window.innerWidth);
  };

  useEffect(() => {
    checkOrientation();
    window.addEventListener(
      "resize",
      (e) => {
        checkOrientation();
      },
      false
    );
  }, []);

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + window.localStorage.getItem("token"),
  };

  const onSubmit = async (inputs) => {
    showEmailError(null);
    fetch(`${process.env.REACT_APP_BACKEND_ENDPOINT}/api/email/signup`, {
      method: "POST",
      headers: headers,
      body: JSON.stringify({
        email: inputs.email.toLowerCase(),
        firstname: inputs.name,
        lastname: inputs.text,
      }),
    })
      .then(async (res) => {
        const data = await res.json();
        if (data.status === "OK") {
          navigate("/create_password", {
            state: { email: inputs.email.toLowerCase() },
          });
        } else {
          showEmailError(true);
        }
      })
      .catch((err) => {
        showEmailError(true);
      })
      .finally(() => setModal(true));
  };

  const onChange = (inputType, value) => {
    if (inputType === "email") {
      return value.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)
        ? { status: "OK", message: "OK" }
        : { status: "KO", message: "El email debe ser válido." };
    } else if (inputType === "name" || inputType === "text") {
      return value.length > 0
        ? { status: "OK", message: "OK" }
        : {
            status: "KO",
            message: "El campo no puede estar vacío",
          };
    }
  };

  return (
    <main className={`login-background ${isPortrait ? "portrait" : ""}`}>
      <section className="img-section">
        <img src="/assets/login-background.svg" />
      </section>
      <section className="login-container">
        <div className="ar-logo-container">
          <img src={Logo} className="ar-logo" />
        </div>
        <div className="login-form">
          <h1 className="login-title">Inscribirse</h1>
          <Form
            formFields={formFields}
            formButtons={formButtons}
            onSubmit={onSubmit}
            onChange={onChange}
          />
        </div>
      </section>
      <section className="heineken-bg-section"></section>
      <Modal
        show={modalVisible}
        title={emailError ? "Email inválido" : "Comprueba tu bandeja de correo"}
        description={
          emailError
            ? "El correo no es válido"
            : "Te hemos enviado instrucciones a tu email"
        }
        buttons={
          <Button
            text="Aceptar"
            appearance="filled"
            color="dark-green"
            width="124"
            onClick={() => setModal(false)}
          />
        }
      />
    </main>
  );
};

export default Signup;
