import { useNavigate, useLocation } from "react-router-dom";
import { useContainerHeight } from "../shared/utils.js";
import Icon from "../components/Icon.js";
import Button from "../components/Button.js";

const SuccessView = () => {
  const navigate = useNavigate();
  const { state } = useLocation();

  useContainerHeight({
    containerClassName: "check-wrapper",
    subHeader: false,
    subFooter: true,
  });

  return (
    <main className="check-wrapper">
      <section className="check-container">
        <Icon
          icon="checkCircle"
          width="52"
          height="52"
          containerSize="64"
          color="#48A742"
          clickable={false}
        />
        <h3 className="check-title">{state.title}</h3>
        <h4 className="check-subtitle">{state.subtitle}</h4>
        <div className="check-buttons">
          <Button
            className="check-button"
            appearance="filled"
            color="dark-green"
            width="auto"
            text={state.button1.text}
            iconLeft=<Icon
              icon={state.button1.icon}
              width="17.01"
              height="17.08"
              color="#FFFFFF"
            />
            onClick={() => navigate(state.button1.link)}
          />
          <Button
            className="check-button"
            appearance="stroke"
            color="dark-green"
            width="auto"
            text={state.button2.text}
            iconLeft=<Icon
              icon={state.button2.icon}
              width="16.5"
              height="19.79"
              color="#005611"
            />
            onClick={() => navigate(state.button2.link)}
          />
        </div>
      </section>
      <Button
        appearance="basic"
        color="aqua"
        width="auto"
        text="Volver a la home"
        onClick={() => navigate("/")}
      />
    </main>
  );
};

export default SuccessView;
