import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useContainerHeight } from "../shared/utils.js";

import Button from "../components/Button.js";
import Icon from "../components/Icon.js";

import column1 from "../assets/1column.png";
import column2 from "../assets/2column.png";
import column3 from "../assets/3column.png";

const SetOfColumns = () => {
  const [selectedColumnCount, selectColumnCount] = useState(2);

  const navigate = useNavigate();

  const columnsCount = [
    { img: column1, count: 1 },
    { img: column2, count: 2 },
    { img: column3, count: 3 },
    // { img: column3, count: 4 },
    // { img: column3, count: 5 },
    // { img: column3, count: 6 },
  ];

  useContainerHeight({
    containerClassName: "set-of-columns",
    subHeader: true,
    subFooter: true,
  });

  return (
    <div className="set-of-columns cards-page">
      <div className="header-and-back">
        <h1 className="header">Columnas</h1>
        <Button
          appearance="basic"
          color="aqua"
          text="Volver"
          width="auto"
          className="back-btn"
          disabled={null}
          onClick={() => navigate(-1)}
        />
      </div>
      <div className="set-of-columns-container">
        <h3>Elige el número de columnas que quieres configurar</h3>
        <div className="column-count-container">
          {columnsCount.map((c, i) => (
            <div
              className={`column-count ${
                c.count === selectedColumnCount && "active"
              }`}
              key={i}
              onClick={() => selectColumnCount(c.count)}
            >
              <img src={c.img} />
              <p>{c.count}</p>
            </div>
          ))}
        </div>

        <Button
          text="Continuar"
          appearance="filled"
          color="dark-green"
          width="146"
          className="continue-btn"
          iconRight={
            <Icon icon="arrowForward" width="15" height="15" color="#FFFFFF" />
          }
          onClick={() =>
            navigate("/1/Columnas/subcategories/" + selectedColumnCount)
          }
        />
      </div>
    </div>
  );
};

export default SetOfColumns;
