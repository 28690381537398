import { Link, useLocation, useNavigate } from "react-router-dom";
import { useState, useContext, useEffect, useRef } from "react";
import Form from "../components/Form.js";
import Icon from "../components/Icon.js";
import ProjectOverlay from "../components/ProjectOverlay.js";

import Logo from "../assets/b-ar-logo.svg";

import { AppContext } from "../AppContext.js";

const headers = {
  "Content-Type": "application/json",
  Authorization: "Bearer " + window.localStorage.getItem("token"),
};

const Summary = () => {
  const projectOverlayRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const { summary, setSummary, columnsConfig, setColumnsConfig } =
    useContext(AppContext);
  const [showProjects, activateShowProjects] = useState(false);
  const [projectId, setProjectId] = useState(undefined);
  const [set_of_columns_Id, updateSetOfColumnsId] = useState(undefined);

  const [formFields, updateFormFields] = useState([
    {
      field: "textfield",
      id: "name",
      key: "name",
      label: "Nombre de este elemento",
      value: "",
      type: "name",
      placeholder: "",
    },
    {
      field: "textarea",
      id: "comment",
      key: "comment",
      label: "Comentarios",
      value: "",
      type: "comment",
      placeholder: "",
    },
    {
      field: "fileUpload",
      key: "files",
      label: "Adjuntar",
      value: "",
      type: "password",
      placeholder: "",
    },
  ]);

  const formButtons = [
    {
      type: "confirm",
      text: "Guardar",
      appearance: "filled",
      color: "dark-green",
      width: 241.5,
      iconLeft: <Icon icon="bookmark" width="14" height="17" color="#FFFFFF" />,
    },
    {
      type: "cancel",
      text: "Eliminar",
      appearance: "stroke",
      color: "red",
      width: 241.5,
      iconLeft: <Icon icon="trash" width="16" height="18" color="#E3010F" />,
    },
  ];

  const onChangeForm = (inputType, value) => {
    if (inputType === "name") {
      return value.length > 0
        ? { status: "OK", message: "OK" }
        : {
            status: "KO",
            message: "El nombre del proyecto no puede estar vacío.",
          };
    } else {
      return { status: "OK", message: "OK" };
    }
  };

  useEffect(() => {
    if (location.state?.config !== undefined) {
      getConfiguration();
    }

    if (Object.keys(summary).length === 0) {
      navigate(
        location.state?.previousPath ? location.state.previousPath : "/",
      );
    }

    return () => {
      formFields.forEach((field) => (field.value = ""));
    };
  }, []);

  //Close the projects overlay when the user clicks outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        projectOverlayRef.current &&
        projectOverlayRef.current.classList.contains("open") &&
        !projectOverlayRef.current.contains(event.target)
      ) {
        activateShowProjects(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      setColumnsConfig((config) => {
        config.columns = [];
        config.trays = [];
        return config;
      });
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [projectOverlayRef]);

  const getConfiguration = () => {
    fetch(
      `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/project/configuration/info/${location.state?.config}`,
      {
        method: "GET",
        headers: headers,
      },
    )
      .then(async (res) => {
        const data = await res.json();
        if (data.status === 200) {
          setProjectId(data.body[0].project_id);
          updateSetOfColumnsId(data.body[0].set_columns_id);
          formFields[0].value = data.body[0].name;
          formFields[1].value = data.body[0].comment;
          formFields[2].value = data.body[0].uploaded_images;
          updateFormFields([...formFields]);
        }
      })
      .catch((error) => {
        console.error(error);
        navigate("/login");
      });
  };

  function getFiles(files) {
    return Promise.all(files.map(getFile));
  }

  function getFile(file) {
    const reader = new FileReader();
    return new Promise((resolve, reject) => {
      reader.onerror = () => {
        reader.abort();
        reject(new Error("Error parsing file"));
      };
      reader.onload = function () {
        let bytes = Array.from(new Uint8Array(this.result));

        let base64StringFile = btoa(
          bytes.map((item) => String.fromCharCode(item)).join(""),
        );

        resolve({
          base64StringFile,
          fileName: file.name,
        });
      };
      reader.readAsArrayBuffer(file);
    });
  }

  const getConfigImages = async () => {
    const files = await getFiles(
      formFields[2].value.filter((f) => f instanceof File),
    );
    const images = formFields[2].value.filter((f) => !(f instanceof File));
    return { files: files, images: images };
  };

  const createConfiguration = async (projectId) => {
    const uploadeImages = await getConfigImages();
    const body = {
      name: formFields[0].value,
      comment: formFields[1].value,
      project_id: projectId,
      element_settings_id: 0,
      image: summary.img,
      uploaded_images: uploadeImages,
      set_columns_id: 0,
    };

    //if it's set of columns or else
    if (columnsConfig.columns.length > 0 && columnsConfig.trays.length > 0) {
      body.set_columns_id = await createSetOfColumns();
    } else {
      body.element_settings_id = summary.elementSettingsId;
    }

    const res = await fetchRequest(
      `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/project/configuration/`,
      "POST",
      body,
    );
    navigateToSuccess();
  };

  const updateConfiguration = async () => {
    const uploadeImages = await getConfigImages();
    const body = {
      id: location.state?.config,
      name: formFields[0].value,
      comment: formFields[1].value,
      project_id: projectId,
      element_settings_id: 0,
      image: summary.img,
      uploaded_images: uploadeImages,
      set_columns_id: 0,
    };

    //if it's set of columns or else
    if (
      columnsConfig.columns.length === 0 &&
      columnsConfig.trays.length === 0
    ) {
      body.element_settings_id = summary.elementSettingsId;
    } else {
      body.set_columns_id = set_of_columns_Id;
    }

    const res = await fetchRequest(
      `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/project/configuration/`,
      "PUT",
      body,
    );
    navigateToSuccess();
  };

  const navigateToSuccess = () => {
    navigate("/success", {
      state: {
        title: "Guardado correctamente",
        subtitle: "¿Que desea hacer ahora?",
        button1: {
          text: "Configurar otro elemento",
          link: "/",
          icon: "build",
        },
        button2: {
          text: "Ir a 'Mis Configuraciones'",
          link: "/projects",
          icon: "bookmarks",
        },
      },
    });
  };

  // location.state?.config ? "config exists" : "new config"
  const createSetOfColumns = async () => {
    const res = await fetchRequest(
      `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/elements/save`,
      "POST",
      columnsConfig,
    );
    setSummary((summary) => {
      return { ...summary, set_columnsId: res.id };
    });
    return res.id;
  };

  // might come in handy later on
  // const updateSetOfColumns = async () => {
  //   const body = {
  //     ...columnsConfig,
  //     setOfColumnsId: set_of_columns_Id,
  //   };
  //   const res = await fetchRequest(
  //     `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/elements/update`,
  //     "POST",
  //     body,
  //   );
  //   setSummary((summary) => {
  //     return { ...summary, set_columnsId: res.id };
  //   });
  //   return res.id;
  // };

  const deleteConfiguration = async () => {
    fetch(
      `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/project/configuration/${location.state?.config}`,
      {
        method: "DELETE",
        headers: headers,
      },
    )
      .then(async (res) => {
        const data = await res.json();
        if (data.status === 200) {
          navigate("/projects");
        }
      })
      .catch((error) => {
        console.error(error);
        navigate("/login");
      });
  };

  const cancelConfiguration = () => {
    navigate("/");
  };

  const showProjectOverlay = (values) => {
    const newFormFields = formFields.map((field) => {
      field.value = values[field.key];
      return field;
    });
    updateFormFields([...newFormFields]);
    if (location.state?.config) {
      updateConfiguration();
    } else {
      activateShowProjects(true);
    }
  };

  const fetchRequest = async (url, method, body) => {
    return fetch(url, {
      method: method,
      headers: headers,
      body: JSON.stringify(body),
    })
      .then(async (res) => {
        const data = await res.json();
        if (data.status === 200) {
          return data;
        } else {
          console.log("ERROR");
          navigate("/login");
        }
      })
      .catch((err) => {
        console.log(err);
        navigate("/login");
      });
  };

  return (
    <>
      <main className={`summary-wrapper ${showProjects && "overlay"}`}>
        <section className="summary-container">
          <Link to="/">
            <img src={Logo} alt="logo" className="ar-logo" />
          </Link>
          <div className="header-subcategory">
            <h1 className="header">Resumen</h1>
            <h3
              className="back"
              onClick={() => {
                return navigate("/");
              }}
            >
              Volver
            </h3>
          </div>
          {Object.keys(summary).length > 0 && (
            <div className="summary">
              <img className="summary-img" alt="summary" src={summary.img} />
              <div className="summary-data-wrapper">
                {summary.configs.map((s, i) => {
                  return (
                    <div key={i} className="summary-data">
                      <p className="summary-data-title">{s.label}: &nbsp;</p>
                      <p className="summary-data-feature">{s.value}</p>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </section>
        <section className="summary-form">
          <Form
            formFields={formFields}
            formButtons={formButtons}
            onSubmit={showProjectOverlay}
            onCancel={
              location.state?.config ? deleteConfiguration : cancelConfiguration
            }
            onChange={onChangeForm}
          />
        </section>
      </main>
      <ProjectOverlay
        show={showProjects}
        ref={projectOverlayRef}
        addConfiguration={createConfiguration}
      />
    </>
  );
};

export default Summary;
