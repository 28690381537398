import { useEffect } from "react";

import Icon from "./Icon.js";
import Logo from "../assets/b-ar-logo-winners.svg";
import FirstQuestionMark from "../assets/winners-question-mark-1.svg";
import SecondQuestionMark from "../assets/winners-question-mark-2.svg";

const WinnersPopup = ({ show, className, winners, close }) => {
  const body = document.querySelector("body");

  useEffect(() => {
    body.style.overflow = show ? "hidden" : "auto";

    document.addEventListener("keydown", onClickESC, false);
    return () => {
      document.removeEventListener("keydown", onClickESC, false);
    };
  }, [show]);

  const onClickESC = (event) => {
    if (event.key === "Escape") {
      close();
    }
  };

  return (
    <div
      className={`modal-dialog ${show ? "active" : "not-active"} ${className}`}
    >
      <div className="content">
        <div className="close">
          <Icon
            icon="close"
            width="12.7"
            height="12.7"
            onClick={close}
            color="#FFFFFF"
          />
        </div>
        <div className="header">
          <div className="question-mark-1">
            <img src={FirstQuestionMark} />
          </div>

          <div className="title">
            <p>Adivina la columna</p>
            <div className="bottom-text">
              <p>incognito con</p>
              <div className="ar-logo">
                <img src={Logo} />
              </div>
            </div>
          </div>
          <div className="question-mark-2">
            <img src={SecondQuestionMark} />
          </div>
        </div>
        <p className="subtitle">Ganadores del concurso</p>
        <div className="winner-list">
          {winners.map((w, i) => (
            <div className="row" key={i}>
              <p className="region">{w.region}</p>
              <p className="winner">{w.winner}</p>
            </div>
          ))}
        </div>
        <img src="assets/michelin-banner.png" className="banner" />

        <div className="reward-text">
          <p>Premio:</p>
          <p>Experiencia con estrella Michelín para dos personas</p>
        </div>
      </div>
    </div>
  );
};

export default WinnersPopup;
