import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";

import Form from "../components/Form.js";
import Modal from "../components/Modal.js";
import Button from "../components/Button.js";
import Logo from "../assets/b-ar-logo.svg";

const PasswordHandler = () => {
  const [formFields, setFormFields] = useState([
    {
      field: "textfield",
      id: "email",
      key: "email",
      label: "Email",
      value: "",
      type: "email",
      placeholder: "Introduce tu email",
    },
    {
      field: "textfield",
      id: "new_password",
      key: "newPassword",
      label: "Nueva contraseña",
      value: "",
      type: "password",
      placeholder: "Introduce tu nueva contraseña",
      visibilityIcon: true,
    },
    {
      field: "textfield",
      id: "repeat_new_password",
      key: "newPasswordConfirm",
      label: "Confirma contraseña",
      value: "",
      type: "password",
      placeholder: "Repite tu nueva contraseña",
      visibilityIcon: true,
    },
  ]);
  const formButtons = [
    {
      type: "confirm",
      text: "Aceptar",
      appearance: "filled",
      color: "dark-green",
      width: 126,
    },
  ];
  const navigate = useNavigate();
  const location = useLocation();
  const [isTooManyAttempts, setTooManyAttempts] = useState(false);
  const [emailError, showEmailError] = useState(false);
  const [isPortrait, setPortrait] = useState(false);
  const [modalVisible, setModal] = useState(false);

  const checkOrientation = () => {
    setPortrait(window.innerHeight > window.innerWidth);
  };

  useEffect(() => {
    checkOrientation();
    window.addEventListener(
      "resize",
      (e) => {
        checkOrientation();
      },
      false
    );

    // Set the email value if user is getting navigated from signup page
    if (location.state?.email) {
      setFormFields((formFields) => {
        formFields.find((field) => field.key === "email").value =
          location.state.email;
        return [...formFields];
      });
    }
  }, []);

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + window.localStorage.getItem("token"),
  };

  const sendEmail = async (inputs) => {
    fetch(`${process.env.REACT_APP_BACKEND_ENDPOINT}/api/email/send`, {
      method: "POST",
      headers: headers,
      body: JSON.stringify({
        email: inputs.email.toLowerCase(),
      }),
    }).then(async (res) => {
      const data = await res.json();
      formFields.find((field) => field.key === "newPasswordConfirm").error = "";
      setFormFields((formFields) => [...formFields]);
    });
  };

  const onSubmit = async (inputs) => {
    let res = await fetch(
      `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/users/password/new`,
      {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
          email: inputs.email.toLowerCase(),
          new_password: inputs.newPassword,
        }),
      }
    )
      .then(async (res) => {
        const data = await res.json();
        window.localStorage.removeItem("token");

        setTooManyAttempts(data.status === "TOO MANY ATTEMPTS");
        showEmailError(data.status === "THERE IS AN ERROR WITH THE EMAIL");
        if (res.status === 200) {
          sendEmail(inputs);
        } else {
          formFields.find((field) => field.key === "newPasswordConfirm").error =
            "Error desconocido!";
          setFormFields((formFields) => [...formFields]);
        }
        setModal(true);
      })
      .catch((err) => {
        console.log(err);
        formFields.find((field) => field.key === "newPasswordConfirm").error =
          "Error desconocido!";
        setFormFields((formFields) => [...formFields]);
      });
  };

  const onChange = (inputType, value) => {
    switch (inputType) {
      case "email":
        return value.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)
          ? { status: "OK", message: "OK" }
          : { status: "KO", message: "El email debe ser válido." };
      case "newPasswordConfirm":
        return value.length < 8
          ? {
              status: "KO",
              message: "La contraseña tiene que tener 8 caracteres.",
            }
          : formFields.find((field) => field.key === "newPassword").value !==
            formFields.find((field) => field.key === "newPasswordConfirm").value
          ? {
              status: "KO",
              message: "La contraseña no coincide con la anterior",
            }
          : { status: "OK", message: "OK" };
      case "newPassword":
        return value.length >= 8
          ? { status: "OK", message: "OK" }
          : {
              status: "KO",
              message: "La contraseña tiene que tener 8 caracteres.",
            };
      default:
        return { status: "KO", message: "error" };
    }
  };

  return (
    <main className={`login-background ${isPortrait ? "portrait" : ""}`}>
      <section className="img-section">
        <img src="/assets/login-background.svg" />
      </section>
      <section className="login-container">
        <div className="ar-logo-container">
          <img src={Logo} className="ar-logo" />
        </div>
        <div className="login-form">
          <h1 className="login-title">Nueva contraseña</h1>
          <Form
            formFields={formFields}
            formButtons={formButtons}
            onSubmit={onSubmit}
            onChange={onChange}
            changePWVisibility={(key, type) => {
              formFields.find((field) => field.key === key).type =
                type === "password" ? "text" : "password";
              setFormFields((formFields) => [...formFields]);
            }}
          />
        </div>
      </section>
      <section className="heineken-bg-section"></section>
      <Modal
        show={modalVisible}
        title={
          isTooManyAttempts
            ? "Límite de intentos"
            : emailError
            ? "Email incorrecto"
            : "Comprueba tu bandeja de correo"
        }
        description={
          isTooManyAttempts
            ? "Ha alcanzado el límite de intentos. Pruebe de nuevo en 24 horas"
            : emailError
            ? ""
            : "Te hemos enviado a tu email un enlace para confirmar tu nueva contraseña"
        }
        buttons={
          <Button
            text="Aceptar"
            appearance="filled"
            color="dark-green"
            width="124"
            onClick={() => {
              setModal(false);
              navigate("/login");
            }}
          />
        }
      />
    </main>
  );
};

export default PasswordHandler;
