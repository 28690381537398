import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect, useContext } from "react";

import Form from "../components/Form.js";
import Button from "../components/Button.js";
import Logo from "../assets/b-ar-logo.svg";
import { AppContext } from "../AppContext.js";

const formButtons = [
  {
    type: "confirm",
    text: "Iniciar sesión",
    appearance: "filled",
    color: "dark-green",
  },
];

const Login = () => {
  const { id, emailToken } = useParams();
  const navigate = useNavigate();
  const { user, setUser, getUserInfo } = useContext(AppContext);
  const [formFields, setFormFields] = useState([
    {
      field: "textfield",
      id: "email",
      key: "email",
      label: "Email",
      value: "",
      type: "email",
      placeholder: "Introduce tu email",
    },
    {
      field: "textfield",
      id: "password",
      key: "password",
      label: "Contraseña",
      value: "",
      type: "password",
      placeholder: "Introduce tu contraseña",
    },
  ]);
  const [isPortrait, setPortrait] = useState(false);
  const headers = {
    "Content-Type": "application/json",
  };

  const checkOrientation = () => {
    setPortrait(window.innerHeight > window.innerWidth);
  };

  const verifyToken = async () => {
    let res = await fetch(
      `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/users/verifyToken`,
      {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
          token: emailToken,
        }),
      }
    )
      .then(async (res) => {
        const data = await res.json();
        if (res.status === 401) navigate("/login");
        if (data.status !== 500) {
          window.localStorage.setItem("token", emailToken);
          setUser({
            id: id,
            nickname: data.body[0].nickname,
            email: data.body[0].email,
          });
          return navigate("/", {
            state: { emailToken: true },
          });
        } else return navigate("/error");
      })
      .catch((error) => {
        console.error(error);
        navigate("/login");
      });
  };

  useEffect(() => {
    if (emailToken) {
      verifyToken();
    }

    checkOrientation();
    window.addEventListener(
      "resize",
      (e) => {
        checkOrientation();
      },
      false
    );
  }, []);

  const OnLogin = async (inputs) => {
    let path;
    let res = await fetch(
      `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/users/login`,
      {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
          email: inputs.email.toLowerCase(),
          password: inputs.password,
        }),
      }
    )
      .then(async (res) => {
        const data = await res.json();
        if (res.status === 401) navigate("/login");
        if (data.token) {
          window.localStorage.setItem("token", data.token);
          setUser({
            id: data.id,
            nickname: data.nickname,
            email: inputs.email.toLowerCase(),
          });
          path = "/";
          return navigate("/");
        } else {
          formFields.find((field) => field.key === "password").error =
            "Error desconocido!";
          setFormFields((formFields) => [...formFields]);
        }
      })
      .catch((err) => {
        formFields.find((field) => field.key === "password").error =
          "Error desconocido!";
        setFormFields((formFields) => [...formFields]);
      });
  };

  const OnChange = (inputType, value) => {
    if (inputType === "password") {
      return value.length >= 8
        ? { status: "OK", message: "OK" }
        : {
            status: "KO",
            message: "La contraseña tiene que tener 8 caracteres.",
          };
    }
    if (inputType === "email") {
      return value.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)
        ? { status: "OK", message: "OK" }
        : { status: "KO", message: "El email debe ser válido." };
    }
  };

  return (
    <main className={`login-background ${isPortrait ? "portrait" : ""}`}>
      <section className="img-section">
        <img loading="lazy" src="/assets/login-background.svg" />
      </section>
      <section className="login-container">
        <div className="ar-logo-container">
          <img src={Logo} className="ar-logo" />
        </div>
        <div className="login-form">
          <h1 className="login-title">Inicia sesión</h1>
          <Form
            formFields={formFields}
            formButtons={formButtons}
            onSubmit={OnLogin}
            onChange={OnChange}
          />
          <div>
            <Button
              appearance="basic"
              className="create-pw-btn"
              color="aqua"
              text="He olvidado mi contraseña"
              width="205"
              onClick={() => navigate("/create_password")}
            />
            <Button
              appearance="basic"
              className="signup-btn"
              color="aqua"
              text="Inscribirse"
              width="205"
              onClick={() => navigate("/signup")}
            />
          </div>

          <p className="raleway-xs">
            Si es tu primera vez usando B-AR clicka en “He olvidado mi
            contraseña” para crear una nueva.
          </p>
        </div>
      </section>
      <section className="heineken-bg-section"></section>
    </main>
  );
};

export default Login;
