import Form from "../components/Form.js";

const onLogin = (e) => {};

const formFields = [
  {
    field: "textfield",
    key: "email",
    label: "Email",
    value: "berkayulguel",
    type: "text",
    placeholder: "Enter your email please",
  },
  {
    field: "textfield",
    key: "password",
    label: "Password",
    value: "123456",
    type: "password",
    placeholder: "Enter your password please",
  },
  {
    field: "textarea",
    key: "description",
    label: "Description",
    value: "",
    placeholder: "Enter your desc please",
  },
];

const FormView = () => {
  return (
    <div className="form-area">
      <Form formFields={formFields} onSubmit={onLogin} />
    </div>
  );
};

export default FormView;
