import { useRef, useState } from "react";
import { useDrag, useDrop } from "react-dnd";

import Icon from "../components/Icon.js";

const TrayCard = ({
  id,
  name,
  img,
  value,
  index,
  order,
  addMore,
  updateTrays,
  moveCard,
  onClick,
}) => {
  const dragRef = useRef(null);
  const previewRef = useRef(null);
  const [trayCount, setTrayCount] = useState(0);

  const [, drop] = useDrop({
    accept: "card",
    hover(item, monitor) {
      if (!previewRef.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = previewRef.current.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      moveCard(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag, preview] = useDrag({
    type: "card",
    item: { type: "card", id, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;
  drag(dragRef);
  drop(preview(previewRef));

  const handleCounter = (operation) => {
    if (operation === "add") {
      setTrayCount(trayCount + 1);
    } else {
      setTrayCount(trayCount - 1);
    }
    onClick(operation);
  };

  return (
    <div
      className={`tray-card ${trayCount === 0 && "no-sub"} ${
        !addMore && "no-add"
      }`}
      ref={previewRef}
      style={{ opacity }}
    >
      <div className="tray-img-and-name">
        {order && (
          <div ref={dragRef}>
            <Icon icon="dragIndicator" width="9" height="15" color="#48A742" />
          </div>
        )}
        <img src={img} className="tray-img" />
        <p className="tray-name">{name}</p>
      </div>
      {!order && (
        <div className="counter-container">
          <Icon
            icon="remove"
            width="11"
            height="1"
            color="#006227"
            containerSize="32"
            clickable={trayCount !== 0}
            onClick={() => handleCounter("remove")}
          />
          <p className="counter">{trayCount}</p>
          <Icon
            icon="add"
            width="11"
            height="11"
            color="#006227"
            containerSize="32"
            clickable={addMore}
            onClick={() => handleCounter("add")}
          />
        </div>
      )}
    </div>
  );
};

export default TrayCard;
