import { useState, useCallback, useContext, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useContainerHeight } from "../shared/utils.js";

import Button from "../components/Button.js";
import Icon from "../components/Icon.js";
import Modal from "../components/Modal.js";
import OrderElementCard from "../components/OrderElementCard.js";
import { AppContext } from "../AppContext.js";

import update from "immutability-helper";

const Columns = () => {
  const { count } = useParams();
  const navigate = useNavigate();
  const { getElements, columnsConfig, setColumnsConfig } =
    useContext(AppContext);
  const [state, setState] = useState({ elements: [] });
  const [cols, setCols] = useState([]);
  const [filters, setFilters] = useState([]);
  const [filtersModal, showFiltersModal] = useState(false);
  const [filterClass, setFilterClass] = useState(null);
  const [filterError, toggleFilterError] = useState(false);
  const [orderModal, showOrderModal] = useState(false);

  const body = document.querySelector("body");

  useEffect(() => {
    getColumns();

    window.scrollTo(0, 0);
    if (count > 3) {
      navigate("/set-of-columns");
    }
  }, []);

  const getColumns = async () => {
    const columnsTypeId = 1;
    const res = await getElements(columnsTypeId);
    setState(res);
    setColumnsFilter(res);
  };

  useContainerHeight({
    containerClassName: "set-of-trays",
    subHeader: true,
    subFooter: true,
  });

  const moveCard = useCallback(
    (dragIndex, hoverIndex) => {
      const dragCard = cols[dragIndex];
      setCols(
        update(cols, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragCard],
          ],
        }),
      );
    },
    [cols],
  );

  const saveOrder = () => {
    setCols(
      cols.map((c, i) => {
        c.order = i;
        c.filters.map((filter) => {
          filter.availableFilters.map((f) => (f.columnOrder = i));
        });
        c.filters.map((f) => (f.columnOrder = i));
        return c;
      }),
    );
    showOrderModal(false);
  };

  const cancelOrder = () => {
    setCols([...cols.sort((a, b) => a.order - b.order)]);
    showOrderModal(false);
  };

  const navigateToTrays = (event) => {
    event.preventDefault();
    const columns = cols.map((c) => {
      return {
        alias: c.alias,
        id: c.elementId,
        model: c.model,
        name: c.name,
        order: c.order,
        picture: c.picture,
      };
    });
    setColumnsConfig({
      columns: [...columns],
      trays: [],
    });
    navigate(`/1/Columnas/subcategories/${count}/trays`);
  };

  const getAvailableFilters = (
    filteredState,
    mainElement,
    filterKey,
    columnOrder,
    isCompatibleWith,
  ) => {
    const filteredWithBrand = filteredState.filter(
      (a) => a.brand_id === mainElement.brand_id,
    );
    // Purify the duplicated filters and map
    let availableFilters = filteredState
      .filter(
        (v, i, a) =>
          a.findIndex(
            (v2) => v2[filterKey + "_id"] === v[filterKey + "_id"],
          ) === i,
      )
      .map((e) => {
        let compatible = true;
        if (isCompatibleWith) {
          compatible = filteredWithBrand
            .filter(
              (f) =>
                f[isCompatibleWith + "_id"] ===
                mainElement[isCompatibleWith + "_id"],
            )
            .some((el) => el[filterKey + "_id"] === e[filterKey + "_id"]);
        }
        return {
          id: e[filterKey + "_id"],
          key: filterKey,
          columnOrder: columnOrder,
          picture: e[filterKey + "_picture"],
          name: e[filterKey + "_name"],
          active: mainElement[filterKey + "_id"] === e[filterKey + "_id"],
          compatible: compatible,
        };
      });

    return availableFilters;
  };

  const setColumnsFilter = (state) => {
    const columns = columnsConfig.columns.map((config) => {
      const filteredState = state.filter((s) => +s.subtype_id === config.id);
      const mainElement = filteredState.find((s) => s.main === 1);

      // Filter tier is brand > kind > texture to determine eaech filter compatibility
      return {
        ...config,
        elementId: mainElement.id,
        filters: [
          {
            key: "brand",
            alias: "marca",
            selectedFilterName: mainElement.brand_name,
            selectedFilterPic: mainElement.brand_picture,
            selectedFilterId: mainElement.brand_id,
            availableFilters: getAvailableFilters(
              filteredState,
              mainElement,
              "brand",
              config.order,
            ).sort((a, b) => a.name.localeCompare(b.nae)),
          },
          {
            key: "kind",
            alias: "tipología",
            selectedFilterName: mainElement.kind_name,
            selectedFilterPic: mainElement.kind_picture,
            selectedFilterId: mainElement.kind_id,
            availableFilters: getAvailableFilters(
              filteredState,
              mainElement,
              "kind",
              config.order,
              "brand",
            ),
          },
          {
            key: "texture",
            alias: "acabado",
            selectedFilterName: mainElement.texture_name,
            selectedFilterPic: mainElement.texture_picture,
            selectedFilterId: mainElement.texture_id,
            availableFilters: getAvailableFilters(
              filteredState,
              mainElement,
              "texture",
              config.order,
              "kind",
            ),
          },
        ],
      };
    });
    setCols(columns);
  };

  const FilterCard = ({ title, img, name, disabled, onClick }) => {
    return (
      <div className={`filter-card ${disabled && "disabled"}`}>
        <h5 className="filter-title">Configurar {title}</h5>
        <div className="filter">
          <img src={img} className="filter-img" />
          <p className="filter-name">{name}</p>
          <div
            className="column-selector"
            onClick={!disabled ? onClick : undefined}
          >
            <Icon
              icon="pencil"
              color="#FFFFFF"
              width="40"
              height="40"
              containerSize="40"
            />
          </div>
        </div>
      </div>
    );
  };

  const updateSelectedFilter = () => {
    const activeFilter = filters.find((f) => f.active);
    const column = cols.find((c) => c.order === activeFilter.columnOrder);

    const updatedFilter = column.filters.find(
      (f) => f.key === activeFilter.key,
    );

    updatedFilter.availableFilters.map((f) => {
      f.active = f.id === activeFilter.id ? true : false;
      return f;
    });
    updatedFilter.selectedFilterId = activeFilter.id;
    updatedFilter.selectedFilterName = activeFilter.name;
    updatedFilter.selectedFilterPic = activeFilter.picture;

    findActualElement(column, activeFilter);

    setCols((cols) => [...cols]);
  };

  const findActualElement = (column, activeFilter) => {
    let allFilters = {};
    allFilters["subtype_id"] = column.id.toString();
    column.filters.map((f) => {
      allFilters[f.key + "_id"] = f.selectedFilterId;
    });

    let actualElement = {};
    actualElement = state.find((el) =>
      Object.entries(allFilters).every(([k, v]) => v === el[k]),
    );

    if (!actualElement) {
      delete allFilters.texture_id;
      actualElement = state.find((el) =>
        Object.entries(allFilters).every(([k, v]) => v === el[k]),
      );
    }

    const filteredState = state.filter((s) => +s.subtype_id === column.id);
    const texture = column.filters.find((f) => f.key === "texture");
    texture.selectedFilterName = actualElement.texture_name;
    texture.selectedFilterPic = actualElement.texture_picture;
    texture.selectedFilterId = actualElement.texture_id;
    texture.availableFilters = getAvailableFilters(
      filteredState,
      actualElement,
      "texture",
      column.order,
      "kind",
    );

    column.elementId = actualElement.id;
    column.model = `/assets/models_opt/column/${getSubtypeFolder(actualElement.subtype_name.toLowerCase())}/${actualElement.model}.glb`;
  };

  const getSubtypeFolder = (subtype) => {
    if (subtype === "cruzcampo") return "cruzcampo2019";

    return subtype;
  };

  function hotspotModal() {
    return (
      <div className="hotspot-modal columns">
        <div className="container">
          <div className="close">
            <Icon
              icon="close"
              width="14"
              height="14"
              color="#FFFFFF"
              onClick={() => {
                body.style.overflow = "auto";
                showFiltersModal(false);
              }}
            />
          </div>
          {filterError && (
            <div className="modal-error">
              <Icon icon="alertCircle" color="#E30613" width="19" height="20" />
              <p className="error-text">
                Esta opción no es compatible con los acabados seleccionados
              </p>
            </div>
          )}
          <div className={`content ${filterClass}`}>
            {filters.map((f) => (
              <div
                className={`filter ${f.key} ${f.compatible ? "" : "not-compatible"} ${f.active ? "active" : "inactive"}`}
                key={f.id}
                id={f.id}
                onMouseOver={() => {
                  if (!f.compatible) toggleFilterError(true);
                }}
                onMouseOut={() => {
                  toggleFilterError(false);
                }}
                onClick={() => {
                  if (f.compatible) {
                    const updatedFilters = filters.map((filter) => {
                      filter.active = filter.id === f.id ? true : false;
                      return filter;
                    });
                    setFilters([...updatedFilters]);
                  }
                }}
              >
                <div className="img-container">
                  <img src={f.picture} />
                </div>
                <p>{f.name}</p>
              </div>
            ))}
          </div>
          <div className="button">
            <Button
              appearance="filled"
              width="80"
              color="dark-green"
              text="Aceptar"
              onClick={() => {
                updateSelectedFilter();
                body.style.overflow = "auto";
                showFiltersModal(false);
              }}
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="set-of-trays cards-page">
      <div className="header-and-back">
        <h1 className="header">Resumen de columnas</h1>
        <Button
          appearance="basic"
          color="aqua"
          text="Volver"
          width="auto"
          className="back-btn"
          onClick={() => navigate(-1)}
        />
      </div>

      <h3>Configura las columnas que has seleccionado</h3>
      <div className="column-card-container">
        {cols.map((column, i) => (
          <div key={i} className="column">
            <div className="column-img">
              <img src={column.picture} />
            </div>
            <div className="title-and-filters">
              <div className="column-title">
                <h4>{column.name}</h4>
                <p>
                  -{" "}
                  {column.filters[0].selectedFilterName +
                    ", " +
                    column.filters[2].selectedFilterName}
                </p>
              </div>

              <div className="filters">
                {column.filters.map((f, i) => (
                  <FilterCard
                    key={i}
                    title={f.alias}
                    img={f.selectedFilterPic}
                    name={f.selectedFilterName}
                    disabled={f.availableFilters.length <= 1}
                    onClick={() => {
                      // toggleFilterError(
                      //   f.availableFilters.some((f) => f.compatible === false),
                      // );
                      setFilters(
                        JSON.parse(JSON.stringify(f.availableFilters)),
                      );
                      setFilterClass(f.alias);
                      body.style.overflow = "hidden";
                      showFiltersModal(true);
                    }}
                  />
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="button-container">
        {count > 1 && (
          <Button
            text="Cambiar orden"
            appearance="stroke"
            color="dark-green"
            width="178"
            className="order-button"
            iconRight={<Icon icon="swapHoriz" width="17" height="14" />}
            disabled={cols.length <= 1}
            onClick={() => showOrderModal(true)}
          />
        )}
        <Button
          text="Continuar"
          appearance="filled"
          color="dark-green"
          width="146"
          className="continue-btn"
          iconRight={
            <Icon icon="arrowForward" width="15" height="15" color="#FFFFFF" />
          }
          onClick={navigateToTrays}
        />
      </div>
      <Modal
        show={orderModal}
        title="Elige el orden"
        className="order-elements-modal"
        description={
          <>
            <Icon
              icon="swapVert"
              width="10.68"
              height="13.65"
              color="#707070"
              containerSize="18"
            />
            De izquierda a derecha
          </>
        }
        elements={
          <div className="order-cards-container">
            {cols.map((c, i) => (
              <OrderElementCard
                key={i}
                id={c.id}
                name={
                  c.name +
                  " - " +
                  c.filters[0].selectedFilterName +
                  ", " +
                  c.filters[2].selectedFilterName
                }
                img={c.picture}
                index={i}
                moveCard={moveCard}
              />
            ))}
          </div>
        }
        buttons={
          <>
            <Button
              text="Cancelar"
              appearance="stroke"
              color="dark-green"
              width="129"
              className="cancel-btn"
              iconRight={<Icon icon="close" width="15" height="15" />}
              onClick={cancelOrder}
            />
            <Button
              text="Guardar"
              appearance="filled"
              color="dark-green"
              width="123"
              className="save-btn"
              iconRight={
                <Icon icon="save" width="17" height="17" color="#FFFFFF" />
              }
              onClick={saveOrder}
            />
          </>
        }
      />
      {filtersModal && hotspotModal()}
    </div>
  );
};

export default Columns;
