import { useEffect, forwardRef } from "react";

const Modal = forwardRef(function Modal(props, ref) {
  const { show, className, icon, title, description, elements, buttons } =
    props;
  const body = document.querySelector("body");

  useEffect(() => {
    body.style.overflow = show ? "hidden" : "auto";
  }, [show]);

  return (
    <div
      className={`modal-dialog ${show ? "active" : "not-active"} ${className}`}
    >
      <div className={`content ${show ? "active" : "not-active"}`} ref={ref}>
        {icon && <div className="icon">{icon}</div>}
        {title && <p className="title">{title}</p>}
        {description && <div className="description">{description}</div>}
        {elements && <div className="elements">{elements}</div>}
        {buttons && <div className="buttons">{buttons}</div>}
      </div>
    </div>
  );
});

export default Modal;
