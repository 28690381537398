import { useState, forwardRef, useContext } from "react";

import Icon from "./Icon.js";
import Gallery from "./Gallery.js";
import { AppContext } from "../AppContext.js";

const ModelSidebar = forwardRef(function ModelSidebar(props, ref) {
  const { sidebarRef, sidebarArrowRef } = ref;

  const { element, type, subtype, showSidebar, toggleSidebar } = props;
  const [showGallery, toggleGallery] = useState(false);
  const [mainImgIndex, setMainImgIndex] = useState(2);
  const { galleryImages } = useContext(AppContext);

  return (
    <>
      <section
        ref={sidebarRef}
        className={`model-menu ${showSidebar ? "open" : "closed"}`}
      >
        <div className={`sidebar ${showGallery && "gallery-open"}`}>
          <div className="model-name">
            <h3>{type}</h3>
            <p className="subtype-name">{subtype}</p>
          </div>
          {element && (
            <>
              <p>{element.description}</p>
              <div className="settings">
                <div className="setting">
                  <div className="icons-bg">
                    <Icon
                      icon="straighten"
                      width="20"
                      height="12"
                      clickable={false}
                    />
                  </div>

                  <div className="description">
                    {element.dimension?.split("x")[2] !== "0" ? (
                      <p>Alto {element.dimension?.split("x")[2]}</p>
                    ) : null}
                    {element.dimension?.split("x")[1] !== "0" ? (
                      <p>Ancho {element.dimension?.split("x")[1]}</p>
                    ) : null}
                    {element.dimension?.split("x")[0] !== "0" ? (
                      <p>Profundidad {element.dimension?.split("x")[0]}</p>
                    ) : null}
                  </div>
                </div>
                <div className="setting">
                  <div className="icons-bg">
                    <Icon
                      icon="thermometer"
                      width="10"
                      height="18"
                      clickable={false}
                    />
                  </div>
                  <div className="description">
                    <p>Temperatura</p>
                    <p>Max {element.temperature_max}</p>
                    <p>Min {element.temperature_min}</p>
                  </div>
                </div>
                <div className="setting">
                  <div className="icons-bg">
                    <Icon
                      icon="bolt"
                      width="10"
                      height="18"
                      clickable={false}
                    />
                  </div>

                  <div className="description">
                    <p>Consumo {element.power}</p>
                  </div>
                </div>
              </div>
            </>
          )}

          {galleryImages.length !== 0 && (
            <>
              <div className="line"></div>
              <p>Galeria</p>
              <div className="gallery-preview">
                {galleryImages.map((img, i) => {
                  return (
                    <img
                      key={i}
                      src={img}
                      className="img"
                      onClick={() => {
                        setMainImgIndex(i);
                        toggleGallery(true);
                      }}
                    />
                  );
                })}
              </div>
            </>
          )}
          {showGallery && (
            <Gallery
              images={galleryImages}
              mainImg={mainImgIndex}
              close={() => toggleGallery(false)}
            />
          )}
        </div>
      </section>
      <div
        ref={sidebarArrowRef}
        className={`sidebar-arrow ${
          showSidebar ? "sidebar-open" : "sidebar-closed"
        } ${showGallery ? "gallery-open" : "gallery-closed"}`}
        onClick={toggleSidebar}
      >
        <img src="/assets/ellipsis.png" />
        <Icon icon="keyboardArrowDown" width="12" height="7" color="#00A5B5" />
      </div>
    </>
  );
});

export default ModelSidebar;
