import { React, useState, useCallback, useContext, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useContainerHeight } from "../shared/utils.js";

import Button from "../components/Button.js";
import Icon from "../components/Icon.js";
import Modal from "../components/Modal.js";
import TrayCard from "../components/TrayCard.js";
import OrderElementCard from "../components/OrderElementCard.js";
import { AppContext } from "../AppContext.js";

import IndividualTray from "../assets/individual-tray.png";
import DoubleTray from "../assets/double-tray.png";
import TripleTray from "../assets/triple-tray.png";

import update from "immutability-helper";

const Trays = () => {
  const { count } = useParams();
  const navigate = useNavigate();
  const { columnsConfig, setColumnsConfig } = useContext(AppContext);
  const [totalTraysValue, setTotalTraysValue] = useState(0);
  const [orderModal, toggleShowModal] = useState(false);
  const [addMoreTrays, setAddMoreTrays] = useState(true);
  const [trays, setTrays] = useState([
    {
      name: "Bandeja Individual",
      img: IndividualTray,
      value: 1,
      id: 359,
      order: 0,
      addMore: true,
    },
    {
      name: "Bandeja Doble",
      img: DoubleTray,
      value: 2,
      id: 360,
      order: 1,
      addMore: true,
    },
    {
      name: "Bandeja Triple",
      img: TripleTray,
      value: 3,
      id: 361,
      order: 2,
      addMore: true,
    },
  ]);
  const [orderTrays, setOrderTrays] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (count > 3) {
      navigate("/set-of-columns");
    }
    disableTrays(count);
  }, []);

  useContainerHeight({
    containerClassName: "set-of-trays",
    subHeader: true,
    subFooter: true,
  });

  const moveCard = useCallback(
    (dragIndex, hoverIndex) => {
      const dragCard = orderTrays[dragIndex];
      setOrderTrays(
        update(orderTrays, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragCard],
          ],
        }),
      );
    },
    [orderTrays],
  );

  const onClick = (tray, operation) => {
    let remainingValueForTrays = 0;
    if (operation === "add") {
      setOrderTrays([...orderTrays, { ...tray, order: orderTrays.length }]);
      setTotalTraysValue(totalTraysValue + tray.value);
      remainingValueForTrays = +count - (totalTraysValue + tray.value);
    } else {
      const orderTrayIndex = orderTrays.findIndex(
        (t) => t.value === tray.value,
      );
      orderTrays.splice(orderTrayIndex, 1);
      setTrayIDs();
      setTotalTraysValue(totalTraysValue - tray.value);
      remainingValueForTrays = +count - (totalTraysValue - tray.value);
    }

    if (remainingValueForTrays === 0) {
      setAddMoreTrays(false);
    } else {
      setAddMoreTrays(true);
    }

    disableTrays(remainingValueForTrays);
  };

  const saveOrder = () => {
    setTrayIDs();
    toggleShowModal(false);
  };

  const cancelOrder = () => {
    setOrderTrays([...orderTrays.sort((a, b) => a.order - b.order)]);
    toggleShowModal(false);
  };

  //Set Tray IDs based on the order
  const setTrayIDs = () => {
    setOrderTrays(
      orderTrays.map((t, i) => {
        t.order = i;
        return t;
      }),
    );
  };

  const disableTrays = (availableSpace) => {
    setTrays(
      trays.map((t) => {
        t.addMore = availableSpace < t.value ? false : true;
        return t;
      }),
    );
  };

  const navigateToThree = () => {
    setColumnsConfig({
      ...columnsConfig,
      trays: orderTrays,
    });
    navigate(`/modelsThree`);
  };

  return (
    <div className={`set-of-trays cards-page`}>
      <div className="header-and-back">
        <h1 className="header">Bandejas</h1>
        <Button
          appearance="basic"
          color="aqua"
          text="Volver"
          width="auto"
          className="back-btn"
          disabled={null}
          onClick={() => navigate(-1)}
        />
      </div>

      <h3>Elige la distribución de bandejas que quieres configurar</h3>
      <div className="tray-card-container">
        {trays.map((tray, i) => (
          <TrayCard
            key={i}
            name={tray.name}
            img={tray.img}
            value={tray.value}
            addMore={tray.addMore}
            onClick={(operation) => onClick(tray, operation)}
            order={false}
          />
        ))}
      </div>
      <div className="button-container">
        {count > 1 && (
          <Button
            text="Cambiar orden"
            appearance="stroke"
            color="dark-green"
            width="178"
            className="order-button"
            iconRight={<Icon icon="swapHoriz" width="17" height="14" />}
            disabled={addMoreTrays}
            onClick={() => toggleShowModal(true)}
          />
        )}
        <Button
          text="Continuar"
          appearance="filled"
          color="dark-green"
          width="146"
          className="continue-btn"
          iconRight={
            <Icon icon="arrowForward" width="15" height="15" color="#FFFFFF" />
          }
          disabled={addMoreTrays}
          onClick={navigateToThree}
        />
      </div>
      <Modal
        show={orderModal}
        title="Elige el orden"
        className="order-elements-modal"
        description={
          <>
            <Icon
              icon="swapVert"
              width="10.68"
              height="13.65"
              color="#707070"
              containerSize="18"
            />
            De izquierda a derecha
          </>
        }
        elements={
          <div className="order-cards-container">
            {orderTrays.map((tray, i) => (
              <OrderElementCard
                key={i}
                id={tray.id}
                name={tray.name}
                img={tray.img}
                value={tray.value}
                index={i}
                moveCard={moveCard}
                order={true}
              />
            ))}
          </div>
        }
        buttons={
          <>
            <Button
              text="Cancelar"
              appearance="stroke"
              color="dark-green"
              width="129"
              className="cancel-btn"
              iconRight={<Icon icon="close" width="15" height="15" />}
              onClick={cancelOrder}
            />
            <Button
              text="Guardar"
              appearance="filled"
              color="dark-green"
              width="123"
              className="save-btn"
              iconRight={
                <Icon icon="save" width="17" height="17" color="#FFFFFF" />
              }
              onClick={saveOrder}
            />
          </>
        }
      />
    </div>
  );
};

export default Trays;
