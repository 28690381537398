import Icon from "./Icon.js";
import Select from "react-select";

const Selectbox = ({ label, value, options, onChange }) => {
    const selectStyles = {
      control: (styles) => ({
        ...styles,
        backgroundColor: "transparent",
        border: 0,
        minHeight: 0,
        height: 25,
        boxShadow: "none",
        width: "auto",
      }),
      option: (styles) => ({
        ...styles,
        paddingTop: 10,
        paddingBottom: 10,
        borderBottom: "1px solid #707070",
        backgroundColor: "#FFFFFF",
        "&:hover": {
          cursor: "pointer",
        },
        "&:active": {
          backgroundColor: "#FFFFFF",
        },
        "&:last-child": {
          borderBottom: "none",
        },
      }),
      menu: (styles) => ({
        ...styles,
        paddingTop: 0,
        paddingBottom: 0,
        width: 200,
      }),
      menuList: (styles) => ({
        ...styles,
        overflowY: "visible",
      }),
      valueContainer: (styles) => ({
        ...styles,
        padding: 0,
      }),
      singleValue: (styles) => ({
        ...styles,
        margin: 0,
      }),
      indicatorsContainer: (styles) => ({
        ...styles,
        width: 20,
      }),
    };
 
    return (
     <div className="select">
        <h3 className="type-name">{label}</h3>
        <Select
          value={value}
          onChange={onChange}
          options={options.map((subtype, index) => {
            return {
              value: subtype.id,
              label: subtype.name,
              picture: subtype.picture,
            };
          })}
          formatOptionLabel={(subType, { context }) => (
            <div className="custom-select">
              {context === "menu" && (
                <img alt="subtype" src={subType.picture} className="img" />
              )}
              <p className={context === "menu" ? "option" : "input"}>
                {subType.label}
              </p>
            </div>
          )}
          styles={selectStyles}
          components={{
            DropdownIndicator: () => (
              <Icon icon="arrowDropDown" width="8" height="5" color="#00A5B5" />
            ),
            IndicatorSeparator: () => null,
          }}
          isSearchable={false}
        />
      </div>
  );
};

export default Selectbox;
