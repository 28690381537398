import Icon from "./Icon.js";
import Button from "../components/Button.js";

const ConfigurationCard = ({ id, image, name, onClick }) => {
  const ConfigBtn = () => {
    return (
      <Button
        appearance="basic"
        color="aqua"
        text="Ver configuracion"
        width="auto"
        className="back-btn"
        iconRight={
          <Icon icon="arrowForward" width="14" height="17" color="#00A5B5" />
        }
        onClick={onClick}
      />
    );
  };

  return (
    <div className="configuration-card">
      <div className="configuration-card-image">
        <img className="img" src={image} />
      </div>
      <div className="configuration-card-description">
        <h3 className="description">{name}</h3>
        <h3>{ConfigBtn()}</h3>
      </div>
    </div>
  );
};

export default ConfigurationCard;
