import { useEffect, forwardRef, useState, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { AppContext } from "../AppContext.js";

import * as THREE from "three";
import * as SkeletonUtils from "three/addons/utils/SkeletonUtils.js";
import { DRACOLoader } from "three/addons/loaders/DRACOLoader.js";
import { GLTFLoader } from "three/addons/loaders/GLTFLoader.js";
import { prune, dedup, textureResize } from "@gltf-transform/functions";

import { WebIO } from "@gltf-transform/core";
import { GLTFExporter } from "three/addons/exporters/GLTFExporter.js";

// import { KHRDracoMeshCompression } from "@gltf-transform/extensions";
// import DracoDecoderModule from "../assets/draco/draco_decoder.js";
// import DracoEncoderModule from "../assets/draco/draco_encoder.js";

import Loader from "../components/Loader.js";

const ThreeView = forwardRef((props, ref) => {
  const { configId, setColumnsId } = useParams(); //subtype var gets undefined if doesnt have subtype
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { columnsConfig, setColumnsConfig, setModelViewerSRC } =
    useContext(AppContext);

  const trays_size = {
    1: 0.23000000342726712,
    2: 0.4600000160098076,
    3: 0.6900000194370746,
  };

  let camera, scene, clock;
  const manager = new THREE.LoadingManager();
  const dracoLoader = new DRACOLoader();
  dracoLoader.setDecoderPath("/threeLib/draco/");
  const loader = new GLTFLoader(manager);
  loader.setDRACOLoader(dracoLoader);

  const loadModel = (modelRoute, modelName, x, y, z) => {
    if (typeof window !== "undefined") {
      loader.load(modelRoute, function (gltf) {
        const model = SkeletonUtils.clone(gltf.scene);

        model.position.x = x; // right - left
        model.position.z = z; // ahead - behind
        model.position.y = y; // up - down

        model.name = modelName;
        scene.add(model);
      });
    }
  };

  const getPositionByValue = (value, i, trays) => {
    const constant = -0.2575;

    if (i === 0) {
      if (value === 1) return -1.43 * constant; // perfect para tray1
      if (value === 2) return -constant; // perfect para tray2
      return -0.54 * constant; // perfect para tray3
    }
    let sum = constant;

    for (let j = 0; j < i; j++) {
      const trayValue = trays[j].value; //get size of previous element
      sum = sum + trays_size[trayValue];
    }

    if (value === 1) sum = sum + constant / 2;
    if (value === 3) sum = sum + 2 * constant;
    return -sum;
  };

  const loadModels = (models) => {
    if (Object.keys(models).length === 0) return;
    let trayVal = 0;
    let initialPos = 0;

    models.trays.map((tray) => {
      trayVal += tray.value;
    });

    // How much init x should be to center
    if (trayVal === 2) {
      initialPos = trays_size[1] / 2;
    } else if (trayVal === 1) {
      initialPos = trays_size[1];
    }

    for (const [i, column] of models.columns.entries()) {
      loadModel(
        column.model,
        `${column.alias.toLowerCase()}.${i}`,
        -0.23 * (models.columns.length - 1 - i) - 0.02 - initialPos,
        -0.5,
        column.alias === "AGUILA" ? 0.53 : 0.6,
      );
    }
    for (const [i, tray] of models.trays.reverse().entries()) {
      let trayXValue = getPositionByValue(tray.value, i, models.trays);
      loadModel(
        `/assets/models_opt/tray/in/TRAY_IN_INOX_${tray.value}_NOBOARD.glb`,
        `${tray.value}.${i}`,
        trayXValue - initialPos,
        -0.5,
        -0.15,
      );
    }
  };

  useEffect(() => {
    init();
  }, []);

  async function init() {
    camera = new THREE.PerspectiveCamera(
      45,
      window.innerWidth / window.innerHeight,
      1,
      100,
    );

    camera.position.set(0, 0, 1.6);
    camera.lookAt(0, -0.5, -10);

    camera.updateProjectionMatrix();

    clock = new THREE.Clock();

    scene = new THREE.Scene();

    const dirLight = new THREE.DirectionalLight(0xffffff);
    dirLight.position.set(-3, 10, -10);
    dirLight.castShadow = true;
    dirLight.shadow.camera.top = 4;
    dirLight.shadow.camera.bottom = -4;
    dirLight.shadow.camera.left = -4;
    dirLight.shadow.camera.right = 4;
    dirLight.shadow.camera.near = 0.1;
    dirLight.shadow.camera.far = 40;
    scene.add(dirLight);

    if (configId && setColumnsId) {
      getSetOfColumnsDetails(setColumnsId); // loading models from already saved set of columns configuration
    } else if (
      columnsConfig.columns.length === 0 &&
      columnsConfig.trays.length === 0
    ) {
      setColumnsConfig({
        ...JSON.parse(window.localStorage.getItem("columnsConfig")),
      });
      loadModels(JSON.parse(window.localStorage.getItem("columnsConfig"))); // loading models from localStorage if the page refreshes on AR exit
    } else {
      loadModels(columnsConfig); // loading the models from scratch
    }
  }

  const columnsConfiguration = {
    AGUILA: {
      name: "aguila",
      picture:
        "https://heinekentest.s3.eu-west-1.amazonaws.com/subtypes/columnas/aguila.png",
    },
    ARYA: {
      name: "arya",
      picture:
        "https://heinekentest.s3.eu-west-1.amazonaws.com/subtypes/columnas/arya.png",
    },
    BONE: {
      name: "bone",
      picture:
        "https://heinekentest.s3.eu-west-1.amazonaws.com/subtypes/columnas/bone.png",
    },
    CRUZCAMPO: {
      name: "cruzcampo2019",
      picture:
        "https://heinekentest.s3.eu-west-1.amazonaws.com/subtypes/columnas/cruzcampo.png",
    },
    HEITUBE: {
      name: "heitube",
      picture:
        "https://heinekentest.s3.eu-west-1.amazonaws.com/subtypes/columnas/heitube.png",
    },
    ICONIC: {
      name: "iconic",
      picture:
        "https://heinekentest.s3.eu-west-1.amazonaws.com/subtypes/columnas/iconic-green.png",
    },
    ARYA_AGUILA: {
      name: "arya-aguila",
      picture:
        "https://heinekentest.s3.eu-west-1.amazonaws.com/subtypes/columnas/iconic-green.png",
    },
  };

  const traysConfiguration = {
    TRAY_IN_INOX_1_NOBOARD: { id: 359, name: "Bandeja Individual", value: 1 },
    TRAY_IN_INOX_2_NOBOARD: { id: 360, name: "Bandeja Doble", value: 2 },
    TRAY_IN_INOX_3_NOBOARD: { id: 361, name: "Bandeja Triple", value: 3 },
  };

  const headers = {
    "Content-Type": "application/json",
    "Cache-Control": "no-cache",
    Authorization: "Bearer " + window.localStorage.getItem("token"),
  };

  const getSetOfColumnsDetails = (setColumnsId) => {
    fetch(
      `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/elements/${setColumnsId}/setColumns/detail`,
      {
        method: "GET",
        headers: headers,
      },
    )
      .then(async (res) => {
        const data = await res.json();
        if (data.status === 200) {
          const body = data.body;

          let configColumns = { columns: [], trays: [] };

          body.models.map((e, i) => {
            const names = [
              "BONE",
              "HEITUBE",
              "ICONIC",
              "ARYA_AGUILA",
              "ARYA",
              "CRUZCAMPO",
              "AGUILA",
            ];

            if (e.model.includes("COLUMN")) {
              const modelName = names.filter((n) => e.model.includes(n))[0];
              configColumns.columns.push({
                id: i,
                name:
                  columnsConfiguration[modelName].name.charAt(0) +
                  columnsConfiguration[modelName].name.slice(1).toLowerCase(),
                picture: columnsConfiguration[modelName].picture,
                order: body.order
                  .split(",")
                  .findIndex(
                    (e) =>
                      e === columnsConfiguration[modelName].name.toUpperCase(),
                  ),
                alias: columnsConfiguration[modelName].name.toUpperCase(),
                model: `/assets/models_opt/column/${columnsConfiguration[
                  modelName
                ].name.toLowerCase()}/${e.model}.glb`,
              });
            } else {
              configColumns.trays.push({
                name: traysConfiguration[e.model].name,
                img: "/static/media/double-tray.f372d7ae1578e187574f.png",
                value: traysConfiguration[e.model].value,
                id: traysConfiguration[e.model].id,
                order: configColumns.trays.length,
                addMore: true,
              });
            }
          });
          configColumns.columns.sort((a, b) => (a.order < b.order ? -1 : 1));
          setColumnsConfig({ ...configColumns });
          loadModels(configColumns);
        } else console.log("ERROR");
      })
      .catch((error) => {
        console.error(error);
      });
  };

  async function exportGLTF(input) {
    const gltfExporter = new GLTFExporter();
    const io = new WebIO();
    const options = {
      trs: true,
      onlyVisible: false,
      binary: true,
      maxTextureSize: 4096000,
      forceIndices: true,
    };
    gltfExporter.parse(
      input,
      async function (glb) {
        const document = await io.readBinary(new Uint8Array(glb));

        await document.transform(
          // Remove unused nodes, textures, or other data.
          prune(),
          // Remove duplicate vertex or texture data, if any.
          dedup(),
          textureResize({ size: [512, 512] }),
        );
        glb = await io.writeBinary(document);

        navigateToModelViewer(glb);
      },
      function (error) {
        console.log("An error happened during parsing", error);
      },
      options,
    );
  }

  // async function loadGLTFs() {
  //   console.log("loadGLTFs");
  //   for (let i = 0; i < routes_left.length; i++) {
  //     setTimeout(() => {
  //       console.log("start");
  //       const routeName = routes_left[i].split("/");
  //       loadModel(
  //         "/assets/models" + routes_left[i],
  //         routeName[routeName.length - 1],
  //         0,
  //         0,
  //         0,
  //       );
  //       console.log("one model loaded");
  //     }, 15000 * i);
  //   }
  // }

  // async function downloadGLTF(input, name) {
  //   const gltfExporter = new GLTFExporter();
  //   const io = new WebIO()
  //     .registerExtensions([KHRDracoMeshCompression])
  //     .registerDependencies({
  //       "draco3d.encoder": await new DracoEncoderModule(),
  //       "draco3d.decoder": await new DracoDecoderModule(),
  //     });
  //   const options = {
  //     trs: true,
  //     onlyVisible: false,
  //     binary: true,
  //     maxTextureSize: 4096000,
  //     forceIndices: true,
  //   };
  //   gltfExporter.parse(
  //     input,
  //     async function (glb) {
  //       const document = await io.readBinary(new Uint8Array(glb));
  //       document
  //         .createExtension(KHRDracoMeshCompression)
  //         .setRequired(true)
  //         .setEncoderOptions({
  //           method: KHRDracoMeshCompression.EncoderMethod.EDGEBREAKER,
  //           encodeSpeed: 0,
  //           decodeSpeed: 0,
  //         });

  //       await document.transform(
  //         // Remove unused nodes, textures, or other data.
  //         prune(),
  //         // Remove duplicate vertex or texture data, if any.
  //         dedup(),
  //         textureResize({ size: [512, 512] }),
  //       );
  //       glb = await io.writeBinary(document);

  //       exportModels(glb, name);
  //     },
  //     function (error) {
  //       console.log("An error happened during parsing", error);
  //     },
  //     options,
  //   );
  // }

  const navigateToModelViewer = (glb) => {
    const src = URL.createObjectURL(
      new Blob([glb], { type: "application/octet-stream" }),
    );
    setModelViewerSRC(src);
    setTimeout(() => {
      navigate(
        configId && setColumnsId
          ? `/modelsViewer/${configId}/${setColumnsId}`
          : `/modelsViewer/`,
      );
    }, 1000);
  };

  // const exportModels = (glb, name) => {
  //   const blob = new Blob([glb], { type: "application/gltf-buffer" });

  //   const link = document.createElement("a");
  //   link.href = URL.createObjectURL(blob);
  //   link.download = name;
  //   link.click();
  //   scene.remove(scene.children.filter((x) => x.name)[0]);
  //   console.timeEnd("loading time");
  // };

  manager.onStart = function (url, itemsLoaded, itemsTotal) {
    setLoading(true);
  };

  manager.onLoad = function () {
    exportGLTF(scene.children.filter((x) => x.name));
    // const model = scene.children.filter((x) => x.name);
    // downloadGLTF(model, model[0].name);
  };

  manager.onError = function (url) {
    console.log("There was an error loading " + url);
  };

  return (
    <>
      <Loader loading={loading} />
    </>
  );
});

export default ThreeView;
