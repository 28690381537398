import { AppContext } from "../AppContext.js";
import { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useContainerHeight } from "../shared/utils.js";

import Button from "../components/Button.js";
import Icon from "../components/Icon.js";
import ConfigurationCard from "../components/ConfigurationCard.js";
import NoConfigImg from "../assets/no-configs.png";

const Configurations = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [configs, setConfigs] = useState({
    data: [],
    projectName: "",
    projectDesc: "",
  });

  const { setColumnsConfig } = useContext(AppContext);

  const headers = {
    "Content-Type": "application/json",
    "Cache-Control": "no-cache",
    Authorization: "Bearer " + window.localStorage.getItem("token"),
  };

  useContainerHeight({
    containerClassName: "configurations",
    subHeader: true,
    subFooter: true,
  });

  useEffect(() => {
    getConfigurations();
  }, []);

  const getConfigurations = () => {
    fetch(
      `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/project/configuration/${id}`,
      {
        method: "GET",
        headers: headers,
      },
    )
      .then(async (res) => {
        const data = await res.json();
        if (data.status === 200) {
          setConfigs({
            data: data.body,
            projectName: data.project_name,
            projectDesc: data.project_comment,
          });
        } else console.log("ERROR");
      })
      .catch((error) => {
        console.error(error);
        navigate("/login");
      });
  };
  const modifySetColumnsConfiguration = (config) => {
    navigate(`/modelsThree/${config.id}/${config.set_columns_id}`);
  };

  const modifyConfiguration = (config) => {
    fetch(
      `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/elements/${config.element_settings_id}/detail`,
      {
        method: "GET",
        headers: headers,
      },
    )
      .then(async (res) => {
        const data = await res.json();
        if (data.status === 200) {
          const body = data.body[0];
          navigate(
            `/${body.type_id}${
              body.subtype_id ? "/" + body.subtype_id : ""
            }/models/${body.name}/${config.id}/${config.element_settings_id}`,
          );
        } else console.log("ERROR");
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // const columnsConfiguration = {
  //   AGUILA: {
  //     name: "aguila",
  //     picture:
  //       "https://heinekentest.s3.eu-west-1.amazonaws.com/subtypes/columnas/aguila.png",
  //   },
  //   ARYA: {
  //     name: "arya",
  //     picture:
  //       "https://heinekentest.s3.eu-west-1.amazonaws.com/subtypes/columnas/arya.png",
  //   },
  //   BONE: {
  //     name: "bone",
  //     picture:
  //       "https://heinekentest.s3.eu-west-1.amazonaws.com/subtypes/columnas/bone.png",
  //   },
  //   CRUZCAMPO: {
  //     name: "cruzcampo2019",
  //     picture:
  //       "https://heinekentest.s3.eu-west-1.amazonaws.com/subtypes/columnas/cruzcampo.png",
  //   },
  //   HEITUBE: {
  //     name: "heitube",
  //     picture:
  //       "https://heinekentest.s3.eu-west-1.amazonaws.com/subtypes/columnas/heitube.png",
  //   },
  //   ICONIC: {
  //     name: "iconic",
  //     picture:
  //       "https://heinekentest.s3.eu-west-1.amazonaws.com/subtypes/columnas/iconic-green.png",
  //   },
  //   ARYA_AGUILA: {
  //     name: "arya-aguila",
  //     picture:
  //       "https://heinekentest.s3.eu-west-1.amazonaws.com/subtypes/columnas/iconic-green.png",
  //   },
  // };

  // const traysConfiguration = {
  //   TRAY_IN_INOX_1_NOBOARD: { name: "Bandeja Individual", value: 1 },
  //   TRAY_IN_INOX_2_NOBOARD: { name: "Bandeja Doble", value: 2 },
  //   TRAY_IN_INOX_3_NOBOARD: { name: "Bandeja Triple", value: 3 },
  // };

  // const modifySetColumnsConfiguration = (config) => {
  //   fetch(
  //     `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/elements/${config.set_columns_id}/setColumns/detail`,
  //     {
  //       method: "GET",
  //       headers: headers,
  //     },
  //   )
  //     .then(async (res) => {
  //       const data = await res.json();
  //       if (data.status === 200) {
  //         const body = data.body;

  //         let configColumns = { columns: [], trays: [] };

  //         body.models.map((e, i) => {
  //           const names = [
  //             "BONE",
  //             "HEITUBE",
  //             "ICONIC",
  //             "ARYA_AGUILA",
  //             "ARYA",
  //             "CRUZCAMPO",
  //             "AGUILA",
  //           ];

  //           if (e.model.includes("COLUMN")) {
  //             const modelName = names.filter((n) => e.model.includes(n))[0];
  //             configColumns.columns.push({
  //               id: i,
  //               name:
  //                 columnsConfiguration[modelName].name.charAt(0) +
  //                 columnsConfiguration[modelName].name.slice(1).toLowerCase(),
  //               picture: columnsConfiguration[modelName].picture,
  //               order: body.order
  //                 .split(",")
  //                 .findIndex(
  //                   (e) =>
  //                     e === columnsConfiguration[modelName].name.toUpperCase(),
  //                 ),
  //               alias: columnsConfiguration[modelName].name.toUpperCase(),
  //               model: `/assets/models_opt/column/${columnsConfiguration[
  //                 modelName
  //               ].name.toLowerCase()}/${e.model}.glb`,
  //             });
  //           } else {
  //             configColumns.trays.push({
  //               name: traysConfiguration[e.model].name,
  //               img: "/static/media/double-tray.f372d7ae1578e187574f.png",
  //               quantity: 0,
  //               value: traysConfiguration[e.model].value,
  //               id: body.order
  //                 .split(",")
  //                 .findIndex((o) => o === traysConfiguration[e.model].name),
  //               addMore: true,
  //             });
  //           }
  //         });
  //         configColumns.columns.sort((a, b) => (a.order < b.order ? -1 : 1));
  //         setColumnsConfig({ ...configColumns });
  //         navigate(`/modelsThree/${config.id}`);
  //       } else console.log("ERROR");
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //     });
  // };

  const backBtn = () => {
    return (
      <Button
        appearance="basic"
        color="aqua"
        text="Volver"
        width="auto"
        className="back-btn"
        disabled={null}
        onClick={() => navigate(-1)}
      />
    );
  };

  // <div className="email">{sendEmailBtn()}</div>
  const sendEmailBtn = () => {
    return (
      <Button
        appearance="basic"
        color="aqua"
        text="Enviar por email"
        width="auto"
        disabled={null}
        iconRight={<Icon icon="mail" width="20" height="16" color="#00A5B5" />}
        onClick={() => alert("mail sended")}
      />
    );
  };

  return (
    <div className="configurations cards-page">
      <div className="configurations-header">
        <div className="title-and-elements-count">
          <h2>{configs.projectName}</h2>
          <h3>{configs.data.length} Elementos</h3>
        </div>
        {backBtn()}
      </div>
      {configs.projectDesc && (
        <div className="header-description">
          <p>{configs.projectDesc}</p>
        </div>
      )}
      {configs.data.length > 0 ? (
        <div className="configuration-cards">
          {configs.data.map((config) => (
            <ConfigurationCard
              id={config.id}
              key={config.id}
              image={config.image}
              name={config.name}
              onClick={() => {
                config.element_settings_id != null
                  ? modifyConfiguration(config)
                  : modifySetColumnsConfiguration(config);
              }}
            />
          ))}
        </div>
      ) : (
        <div className="no-configurations">
          <img src={NoConfigImg} className="img" />
          <h4 className="title">Proyecto vacío</h4>
          <p className="description">
            El proyecto actualmente carece de contenido. Este espacio está listo
            para ser llenado con tu visión y planificación.
          </p>
          <Button
            text="Configurar proyecto"
            appearance="filled"
            color="dark-green"
            width="auto"
            iconRight={
              <Icon
                icon="arrowForward"
                width="15"
                height="15"
                color="#FFFFFF"
              />
            }
            onClick={() => navigate("/")}
          />
        </div>
      )}
    </div>
  );
};

export default Configurations;
