import { useEffect, useState, useContext } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useContainerHeight } from "../shared/utils.js";

import SubCategoryCard from "../components/SubCategoryCard.js";
import Button from "../components/Button.js";
import Icon from "../components/Icon.js";
import { AppContext } from "../AppContext.js";

import SetColumnsImg from "../assets/set-columns.png";

const SubCategories = () => {
  const { id, name, count } = useParams();
  const [subCategories, setSubCategories] = useState([]);
  const [totalColumnAdded, setTotalColumnAdded] = useState(0);
  const [addMoreColumn, toggleAddMoreColumn] = useState(true);
  const [columns, addColumn] = useState([]);

  const { setColumnsConfig } = useContext(AppContext);

  const navigate = useNavigate();

  const location = useLocation();

  const showSetOfColumns =
    process.env.REACT_APP_FRONTEND_ENVIRONMENT === "development";

  useEffect(() => {
    getSubCategories();
    window.scrollTo(0, 0);
  }, []);

  useContainerHeight({
    containerClassName: "cards-page",
    subHeader: true,
    subFooter: true,
  });

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + window.localStorage.getItem("token"),
  };

  const mainColumns = {
    aguila: "COLUMN_AGUILAFLAGSHIP_AGUILADORADA_RETRO_CFT",
    arya: "COLUMN_ARYA_HEINEKENSILVER_N_CFT",
    bone: "COLUMN_BONE_AGUILADORADA_CHROME-MATTE_CFT",
    cruzcampo2019: "COLUMN_CRUZCAMPO2019_CRUZCAMPO_N_CFT",
    heitube: "COLUMN_HEITUBE_HEINEKEN_COPPER-MATTE_CFT",
    iconic: "COLUMN_ICONIC_GREEN_N_CFT",
    "arya-aguila": "COLUMN_ARYA_AGUILADORADA_N_CFT",
  };

  const getSubCategories = async () => {
    fetch(
      `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/elements/${id}/subtype/`,
      {
        method: "GET",
        headers: headers,
      },
    )
      .then(async (res) => {
        const data = await res.json();
        if (data.status === 200) {
          setSubCategories(
            data.body
              .map((subcategory) => ({
                ...subcategory,
                model: `/assets/models/column/${subcategory.alias.toLowerCase()}/${
                  mainColumns[subcategory.alias.toLowerCase()]
                }.glb`,
              }))
              .sort((a, b) => (a.order > b.order ? 1 : -1)),
          );
        } else navigate("/login");
      })
      .catch((err) => console.log(err));
  };

  const onClick = (subcategory, operation) => {
    if (!count) {
      navigate(`/${id}/${subcategory.id}/models/${subcategory.name}`);
    }
    const newSubcats = subCategories;
    const sendColumns = columns;

    const subcatIndex = subCategories.findIndex((s) => s.id === subcategory.id);

    if (operation === "remove") {
      const subCatIndexR = sendColumns.findIndex((s) => s === subcategory);
      sendColumns.splice(subCatIndexR, 1);
      addColumn([...sendColumns]);
      setTotalColumnAdded(totalColumnAdded - 1);
      toggleAddMoreColumn(true);
      return;
    }

    sendColumns.push(JSON.parse(JSON.stringify(newSubcats[subcatIndex]))); // necesary to clone the object and not send it by reference
    sendColumns.map((c, i) => (c.order = i));
    addColumn(sendColumns);

    setTotalColumnAdded(totalColumnAdded + 1);

    //The reason we calculate by + 1 is because the state never changes in its scope
    if (totalColumnAdded + 1 >= +count) {
      toggleAddMoreColumn(false);
    }
  };

  const navigateToTrays = () => {
    setColumnsConfig({ columns: columns, trays: [] });
    navigate("columns");
  };

  return (
    <div className={`cards-page ${name.toLowerCase()}`}>
      <div className="header-subcategory">
        <h1 className="header">{name}</h1>
        <h3
          className="back"
          onClick={() => {
            return navigate("/");
          }}
        >
          Volver
        </h3>
      </div>
      {count && (
        <h3 className="subtitle-columns">Selecciona tus {count} columnas</h3>
      )}
      <div className="cards-container">
        {!count &&
          showSetOfColumns &&
          location.pathname.includes("/Columnas") && (
            <SubCategoryCard
              key={0}
              id={0}
              name="Set de columnas"
              count=""
              image={SetColumnsImg}
            />
          )}
        {subCategories.map((s, i) => {
          return (
            <SubCategoryCard
              key={i}
              id={s.id}
              name={s.name}
              image={s.picture}
              setOfColumnsActive={count}
              addMoreColumn={addMoreColumn}
              onClick={(operation) => onClick(s, operation)}
            />
          );
        })}
      </div>
      {count && (
        <Button
          text="Seleccionar"
          appearance="filled"
          color="dark-green"
          width="153"
          className="select-btn"
          iconRight={
            <Icon icon="check" width="15" height="10.5" color="#FFFFFF" />
          }
          disabled={addMoreColumn}
          onClick={navigateToTrays}
        />
      )}
    </div>
  );
};

export default SubCategories;
