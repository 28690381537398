import { React, useState, useCallback, useContext, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useContainerHeight } from "../shared/utils.js";

import Button from "../components/Button.js";
import Icon from "../components/Icon.js";
import { AppContext } from "../AppContext.js";

import chrome from "../assets/chrome.png";
import safari from "../assets/safari.png";
import arcode from "../assets/arcore.png";
import android from "../assets/android.svg";
import ios from "../assets/iOS.svg";

const Instructions = () => {
  const { count } = useParams();
  const navigate = useNavigate();

  return (
    <div className="instructions-page">
      <div className="header-and-back">
        <h1 className="header">Configuración Realidad Aumentada (AR)</h1>
        <Button
          appearance="basic"
          color="aqua"
          text="Volver"
          width="auto"
          className="back-btn"
          disabled={null}
          onClick={() => navigate(-1)}
        />
      </div>

      <div className="title">
        <img src={chrome} />
        <h3 className="title-text">Activar AR desde Google Chrome</h3>
      </div>
      <p className="version">
        Versión recomendada: 108.0.5359 2022-11-29 (o superior)
      </p>
      <h4 className="subtitle">Requisitos</h4>
      <ul>
        <li>
          Dispositivo compatible con <b>Android 10</b> o superior.
        </li>
        <li>
          Tener la aplicación <b>Google Play Services for AR</b> instalada y
          habilitada.
          {
            // <img src={arcode} width={225} height={69} />
          }
        </li>
      </ul>
      <h4 className="subtitle">Pasos</h4>
      <ol type="1">
        <li>Dentro del navegador, conceder permisos de realidad aumentada.</li>
        <li>Actualizar la pgina para activar estos permisos.</li>
      </ol>

      <div className="title">
        <img src={safari} />
        <h3 className="title-text">Activar AR desde Safari</h3>
      </div>
      <p className="version">
        Versión recomendada: 16.3 MacOS , 5.1.7 Windows.(o superior)
      </p>
      <h4 className="subtitle">Requisitos</h4>
      <ul>
        <li>
          Dispositivo compatible a partir de la versión 12.0 de Safari en
          dispositivos iOS.
        </li>
      </ul>
      <h4 className="subtitle">Pasos</h4>
      <ol type="1">
        <li>
          Abre la aplicación de <b>Configuración</b> de tu dispositivo
        </li>
        <li>
          Desliza hacia abajo y toca <b>“Safari”</b>
        </li>
        <li>Activa la opción “AR Quick Look”</li>
      </ol>

      <p>
        Recuerda visitar la tienda oficial de aplicaciones de tu dispositivo
        para realizar la actualización correspondiente del navegador.
      </p>
      <div className="stores">
        <a href="https://developer.apple.com/augmented-reality/arkit/">
          <img src={ios} width={120} height={35} />
        </a>
        <a href="https://play.google.com/store/apps/details?id=com.google.ar.core&hl=en_US">
          <img src={android} width={120} height={35} />
        </a>
      </div>
    </div>
  );
};

export default Instructions;
