import { Link, useLocation } from "react-router-dom";

const ErrorView = () => {
  const { state } = useLocation();

  return (
    <main className="summary_wrapper">
      <section className="summary_container">
        <h3 className="summary_title">ERROR</h3>
        <a>
          <Link to="/"> Volver a la home </Link>
        </a>
      </section>
    </main>
  );
};

export default ErrorView;
