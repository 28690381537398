import { useState, useEffect } from "react";

import Icon from "./Icon.js";
import NoConfigImg from "../assets/no-configs.png";

const ProjectCard = ({
  id,
  images,
  name,
  elementCount,
  selectionActive,
  onClick,
}) => {
  const [selected, select] = useState(false);

  useEffect(() => {
    if (!selectionActive) {
      select(false);
    }
  }, [selectionActive]);

  const showImages = () => {
    switch (images.length) {
      case 0:
        return <img src={NoConfigImg} />;
      case 1:
        return <img src={images[0]} />;
      case 2:
        return (
          <div className="two-images">
            {images.map((img, i) => (
              <img key={i} src={img} />
            ))}
          </div>
        );
      // Default condition is either 3 images or more than 3 images
      default:
        return (
          <div className="more-than-two-images">
            <img className="img-left" src={images[0]} />
            <div className="images-right">
              {images.slice(1, images.length === 3 ? 3 : 2).map((img, i) => (
                <img key={i} src={img} />
              ))}
              {images.length > 3 && (
                <div className="img-count">+{images.length - 2}</div>
              )}
            </div>
          </div>
        );
    }
  };
  return (
    <div
      className={`project-card ${selected ? "selected" : ""}`}
      onClick={() => {
        onClick(id);
        select(!selected);
      }}
    >
      {selectionActive && (
        <div className="check-icon">
          {selected ? (
            <Icon icon="checkCircleChecked" />
          ) : (
            <svg height="20" width="20">
              <circle
                cx="10"
                cy="10"
                r="9"
                stroke="#005611"
                strokeWidth="1"
                fill="none"
              />
            </svg>
          )}
        </div>
      )}
      <div className="all-images">{showImages()}</div>
      <div className="bottom-section">
        <div className="info">
          <p className="name">{name}</p>
          <p className="element-count">
            {elementCount} {elementCount === 1 ? "elemento" : "elementos"}
          </p>
        </div>
        <Icon icon="share" width="18" height="20" color="#00A5B5" />
      </div>
    </div>
  );
};

export default ProjectCard;
