import { useNavigate, useLocation } from "react-router-dom";

const CheckView = async (event) => {
  const navigate = useNavigate();
  const { state } = useLocation();

  if (state == null) {
    navigate("/success", { state: state });
  } else {
    navigate("/error", { state: { message: "Unknown error" } });
  }
};

export default CheckView;
