import { useState, useEffect, useContext, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useContainerHeight } from "../shared/utils.js";

import Button from "../components/Button.js";
import Icon from "../components/Icon.js";
import Modal from "../components/Modal.js";
import Form from "../components/Form.js";
import ProjectCard from "../components/ProjectCard.js";
import Frame from "../assets/create-project-frame.png";
import ReactPaginate from "react-paginate";
import { AppContext } from "../AppContext.js";

const formFields = [
  {
    field: "textfield",
    id: "name",
    key: "name",
    label: "",
    value: "",
    type: "text",
    placeholder: "Nombre del proyecto",
  },
  {
    field: "textarea",
    id: "desc",
    key: "desc",
    label: "",
    value: "",
    type: "text",
    placeholder: "Descripción del proyecto (opcional)",
  },
];

const formButtons = [
  {
    type: "confirm",
    text: "Crear",
    appearance: "filled",
    color: "dark-green",
    width: 74,
  },
];

const Projects = () => {
  const createProjectModalRef = useRef(null);
  const { archived } = useParams();
  const navigate = useNavigate();
  const { user, sendProjectsReq } = useContext(AppContext);
  const [projects, setProjects] = useState({ data: [], total: 0 });
  const [selectionActive, setSelection] = useState(false);
  const [selectedProjects, selectProjects] = useState([]);
  const [page, setPage] = useState(0);
  const [successModal, showSuccessModal] = useState(false);
  const [createProjectModal, showCreateProjectModal] = useState(false);

  const headers = {
    "Content-Type": "application/json",
    "Cache-Control": "no-cache",
    Authorization: "Bearer " + window.localStorage.getItem("token"),
  };

  useEffect(() => {
    getProjects(0);
  }, [archived, user]);

  useEffect(() => {
    document.querySelector("body").style.background = "#FFFFFF";

    return () => {
      document.querySelector("body").style.background = "#F7f7f7";
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        createProjectModalRef.current &&
        createProjectModalRef.current.classList.contains("active") &&
        !createProjectModalRef.current.contains(event.target)
      ) {
        showCreateProjectModal(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [createProjectModalRef]);

  useContainerHeight({
    containerClassName: "projects",
    subHeader: true,
    subFooter: true,
  });

  const getProjects = (pageNum) => {
    const active = archived ? 0 : 1;
    if (Object.keys(user).length !== 0) {
      sendProjectsReq(active, pageNum)
        .then((data) => {
          setProjects({ data: data.body, total: data.total });
        })
        .catch((err) => {
          setProjects({ data: [], total: 0 });
          console.log(err);
        });
    }
  };

  const createProject = async (values) => {
    fetch(`${process.env.REACT_APP_BACKEND_ENDPOINT}/api/project/`, {
      method: "POST",
      headers: headers,
      body: JSON.stringify({
        name: values.name,
        description: values.desc,
        userId: user.id,
      }),
    })
      .then(async (res) => {
        const data = await res.json();
        if (data.status === 200) {
          getProjects(0);
          setPage(0);
          selectProjects([]);
          setSelection(false);
        }
      })
      .catch((error) => {
        console.error(error);
        navigate("/login");
      });

    showCreateProjectModal(false);
  };

  const archiveProjects = () => {
    const active = archived ? 1 : 0;
    fetch(
      `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/project/toggleActive`,
      {
        method: "POST",
        headers: headers,
        body: JSON.stringify({ active: active, projectIds: selectedProjects }),
      }
    )
      .then(async (res) => {
        const data = await res.json();
        if (data.status === 200) {
          getProjects(0);
          setPage(0);
          selectProjects([]);
          setSelection(false);
          showSuccessModal(true);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const deleteProjects = () => {
    fetch(`${process.env.REACT_APP_BACKEND_ENDPOINT}/api/project/delete`, {
      method: "POST",
      headers: headers,
      body: JSON.stringify({ projectIds: selectedProjects }),
    })
      .then(async (res) => {
        const data = await res.json();
        if (data.status === 200) {
          getProjects(0);
          setPage(0);
          selectProjects([]);
          setSelection(false);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleSelection = (id) => {
    if (selectedProjects.includes(id)) {
      selectedProjects.splice(selectedProjects.indexOf(id), 1);
    } else {
      selectedProjects.push(id);
    }
    selectProjects([...selectedProjects]);
  };

  const navigateToConfigs = (id) => {
    navigate(`/projects/${id}/configurations`);
  };

  const onChangeForm = (inputType, value) => {
    if (inputType === "name") {
      return value.length > 0
        ? { status: "OK", message: "OK" }
        : {
            status: "KO",
            message: "El nombre del proyecto no puede estar vacío.",
          };
    } else {
      return { status: "OK", message: "OK" };
    }
  };

  const backBtn = () => {
    return (
      <Button
        appearance="basic"
        color="aqua"
        text="Volver"
        width="auto"
        className="back-btn"
        disabled={selectionActive}
        onClick={() => navigate(-1)}
      />
    );
  };

  return (
    <div
      className={`projects cards-page ${selectionActive && "selection-active"}`}
    >
      <div className="header-and-select">
        <div className="header">
          <h1>Mis configuraciones</h1>
          {backBtn()}
        </div>
        {selectionActive ? (
          <p className="elements-selected">
            {selectedProjects.length + " elemento seleccionado"}
          </p>
        ) : (
          <Button
            appearance="basic"
            className="select-btn"
            color="aqua"
            text="Seleccionar"
            width="auto"
            onClick={() => setSelection(true)}
          />
        )}
      </div>
      <div className="cards-container">
        {!selectionActive && !archived && (
          <div
            className="project-card create-project"
            onClick={() => showCreateProjectModal(true)}
          >
            <img src={Frame} className="frame" />
            <p>Crear un proyecto</p>
          </div>
        )}
        {projects.data.length > 0 &&
          projects.data.map((project) => (
            <ProjectCard
              id={project.id}
              key={project.id}
              images={project.image}
              name={project.name}
              elementCount={project.configurations}
              selectionActive={selectionActive}
              onClick={selectionActive ? handleSelection : navigateToConfigs}
            />
          ))}
      </div>

      {selectionActive ? (
        <div className="projects-buttons">
          <Button
            text="Cancelar"
            appearance="basic"
            color="aqua"
            width="97"
            onClick={() => {
              selectProjects([]);
              setSelection(false);
            }}
          />
          <Button
            text={archived ? "Desarchivar" : "Archivar"}
            appearance="filled"
            color="dark-green"
            width="auto"
            className="archive-btn"
            iconLeft={
              <Icon
                icon={archived ? "unarchive" : "archive"}
                width="17"
                height="17"
                color="#FFFFFF"
              />
            }
            disabled={selectedProjects.length === 0}
            onClick={archiveProjects}
          />
          <Button
            text="Eliminar"
            appearance="filled"
            color="red"
            width="114"
            className="delete-btn"
            iconLeft={
              <Icon icon="trash" width="16" height="18" color="#FFFFFF" />
            }
            s
            disabled={selectedProjects.length === 0}
            onClick={deleteProjects}
          />
        </div>
      ) : (
        <ReactPaginate
          previousLabel={
            <Icon
              icon="chevronLeft"
              containerSize="20"
              width="5"
              height="9"
              color="#006227"
            />
          }
          nextLabel={
            <Icon
              icon="chevronRight"
              containerSize="20"
              width="5"
              height="9"
              color="#006227"
            />
          }
          pageClassName="page-item"
          previousClassName="page-item"
          nextClassName="page-item"
          breakLabel="..."
          breakClassName="page-item"
          pageCount={projects.total <= 8 ? 0 : Math.ceil(projects.total / 8)}
          marginPagesDisplayed={1}
          pageRangeDisplayed={5}
          forcePage={page}
          onPageChange={(e) => {
            setPage(e.selected);
            getProjects(e.selected);
          }}
          containerClassName="pagination-container"
          activeClassName="active"
          renderOnZeroPageCount={null}
        />
      )}

      <Modal
        show={successModal}
        title={
          archived
            ? "Configuración desarchivada correctamente"
            : "Configuración archivada correctamente"
        }
        description="¿Qué desea hacer?"
        buttons={
          <>
            <Button
              text={archived ? "Ver mis configuraciones" : "Ver mis archivados"}
              appearance="filled"
              color="dark-green"
              width="auto"
              onClick={() => {
                showSuccessModal(false);
                navigate(`${archived ? "/projects" : "archived"}`);
              }}
            />
            <Button
              text="Continuar"
              appearance="stroke"
              color="grey"
              width="102"
              onClick={() => showSuccessModal(false)}
            />
          </>
        }
      />

      <div className="create-project-modal">
        <Modal
          show={createProjectModal}
          title="Crear proyecto"
          elements={
            <Form
              formFields={formFields}
              formButtons={formButtons}
              onSubmit={createProject}
              onChange={onChangeForm}
            />
          }
          ref={createProjectModalRef}
        />
      </div>
    </div>
  );
};

export default Projects;
