import { useParams, useNavigate, useLocation, Link } from "react-router-dom";
import { React, useEffect, useState, useContext } from "react";
import { useContainerHeight } from "../shared/utils.js";

import Button from "../components/Button.js";
import Icon from "../components/Icon.js";

import Logo from "../assets/b-ar-logo.svg";
import { AppContext } from "../AppContext.js";

import "@google/model-viewer/dist/model-viewer";

const ThreeModelViewer = () => {
  const { configId, setColumnsId } = useParams();

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [canActivateAR, setCanActivateAR] = useState(false);
  const [showArIcons, toggleArIcons] = useState(true);
  const [loading, setLoading] = useState(false);
  const [showImgDownloadModal, toggleImgDownloadModal] = useState(false);

  const [model_route, updateModelRoute] = useState("");

  const {
    columnsConfig,
    generateSetColumnsSummary,
    modelViewerSRC,
    setModelViewerSRC,
  } = useContext(AppContext);

  const onLoad = () => {
    setLoading(false);
  };

  const save = async () => {
    window.localStorage.removeItem("columnsConfig");
    await generateSetColumnsSummary(columnsConfig);

    return navigate("/summary", {
      state: {
        previousPath: pathname,
        project: null,
        config: configId,
        element: "set-of-columns",
      },
    });
  };

  const toggleShowArIcons = (status) => {
    if (status === "session-started") toggleArIcons(false);
    if (status === "not-presenting") toggleArIcons(true);
  };

  useEffect(() => {
    if (configId && setColumnsId && !modelViewerSRC) {
      navigate(`/modelsThree/${configId}/${setColumnsId}`);
      return;
    } else if (!configId && !setColumnsId && !modelViewerSRC) {
      navigate("/modelsThree");
      return;
    }
    const modelViewer = document.querySelector("model-viewer");
    modelViewer.addEventListener("load", onLoad);
    modelViewer.addEventListener("progress", onProgress);
    modelViewer.addEventListener("ar-status", (event) => {
      toggleShowArIcons(event.detail.status);
    });

    window.localStorage.setItem("columnsConfig", JSON.stringify(columnsConfig));
    updateModelRoute(modelViewerSRC);

    setCanActivateAR(modelViewer.canActivateAR);

    return () => {
      setModelViewerSRC(null);
    };
  }, []);

  useContainerHeight({
    containerClassName: "model-wrapper",
  });

  const exportPNG = async () => {
    setLoading(true);
    const png = await document.querySelector("model-viewer").toDataURL();
    const link = document.createElement("a");
    link.href = png;
    link.download = Date.now() + ".png";
    link.click();
    setTimeout(() => {
      setLoading(false);
      toggleImgDownloadModal(true);
    }, 3500);
  };

  // On change any part of model
  const onProgress = (event) => {
    setLoading(true);
    if (event.detail.totalProgress === 1) {
      setLoading(false);
    }
  };

  return (
    <main>
      <div className={"model-wrapper"}>
        <div className={"select-and-logo sidebar-closed"}>
          <Link to="/">
            <img alt="ar-logo" src={Logo} className="ar-logo" />
          </Link>
        </div>
        <section className={"model-background"}>
          <model-viewer
            src={model_route}
            ar=""
            ar-mode="fixed"
            align-model="origin"
            ar-modes="webxr scene-viewer quick-look"
            high-performance="low-power"
            camera-controls
            data-js-focus-visible=""
            enable-pan=""
            minimumRenderScale=""
            seamless-poster=""
            shadow-intensity="1"
            exposure="2"
            environment-image="/assets/ENVIRONMENT.png"
          >
            <div className="progress-bar hide model-viewer" slot="progress-bar">
              <div className="update-bar"></div>
            </div>
            <button
              slot="ar-button"
              className={`ar-button ${
                !canActivateAR && !showArIcons ? "hide" : "show"
              }`}
            >
              <Icon icon="viewInAR" width="22" height="22" color="#FFFFFF" />
            </button>
            <div
              className={`export-png-icon ${
                !showArIcons || loading ? "hide" : "show"
              } ${canActivateAR && "ar-active"}`}
              onClick={exportPNG}
            >
              <Icon
                icon="photoCameraFrame"
                width="26"
                height="26"
                containerSize="26"
              />
              <Icon icon="photoCamera" width="18" height="14" />
            </div>
            <div id="ar-prompt">
              <img alt="ar" src="/assets/ar_hand_prompt.png" />
            </div>
          </model-viewer>
        </section>
      </div>
      <section className="model-buttons">
        <Button
          appearance="basic"
          className={"restart-btn sidebar-closed"}
          color="aqua"
          text="Restablecer"
          iconLeft={
            <Icon icon="restart" color="#00A5B5" width="16" height="20" />
          }
          onClick={() => {
            window.localStorage.removeItem("columnsConfig");
            navigate("/set-of-columns");
          }}
        />
        <div className="configure-and-save">
          <Button
            appearance="stroke"
            color="dark-green"
            text="Guardar"
            iconLeft={<Icon icon="bookmark" width="14" height="17" />}
            onClick={save}
          />
        </div>
      </section>
    </main>
  );
};

export default ThreeModelViewer;
