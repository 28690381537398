import { useEffect, useState, useContext } from "react";
import { Route, Routes, Navigate, Outlet, useLocation } from "react-router-dom";
import "./styles/main.scss";
import heineken from "./assets/heineken.png";
import Button from "./components/Button.js";
import Header from "./components/Header.js";
import Footer from "./components/Footer.js";
import Modal from "./components/Modal.js";
import Icon from "./components/Icon.js";

import Profile from "./views/profile.js";
import Projects from "./views/projects.js";
import Configurations from "./views/configurations.js";
import FormView from "./views/formView.js";
import Home from "./views/home.js";
import SubCategories from "./views/subCategories.js";
import Login from "./views/login.js";
import Signup from "./views/signup.js";
import ModelViewer from "./views/modelViewer.js";
import Summary from "./views/summary.js";
import CheckView from "./views/checkView.js";
import Success from "./views/successView.js";
import Error from "./views/errorView.js";
import Password from "./views/passwordHandler.js";
import Instructions from "./views/instructions.js";
// import ModelViewerThreeView from "./views/modelViewerThree.js";
import Three from "./views/threeVIEW.js";
import ThreeModelViewer from "./views/three-modelViewer.js";

import SetOfColumns from "./views/setOfColumns.js";
import Trays from "./views/trays.js";
import Columns from "./views/columns.js";
import ErrorBoundary from "./components/ErrorBoundary.js";

import { AppProvider, AppContext } from "./AppContext.js";

const ProtectedRoute = ({ token, redirectPath = "/login", children }) => {
  if (!token) return <Navigate to={redirectPath} replace />;
  return children ? children : <Outlet />;
};

function App() {
  const location = useLocation();
  const { user, getUserInfo } = useContext(AppContext);
  const [showDisclaimer, toggleDisclaimer] = useState(false);
  const userAgent = navigator.userAgent.toLowerCase();

  const token = window.localStorage.getItem("token");
  const isTablet =
    /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
      userAgent,
    );

  const checkOrientation = () => {
    const landscape = window.orientation === 90;
    toggleDisclaimer(landscape ? true : false);
  };

  useEffect(() => {
    checkOrientation();
    window.addEventListener(
      "orientationchange",
      (e) => {
        checkOrientation();
      },
      false,
    );

    if (token && Object.values(user).length === 0) {
      getUserInfo();
    }
  }, [user]);

  return (
    <div className="App">
      <Modal
        show={showDisclaimer}
        className="mobile-rotation-modal"
        icon={
          <div className="rotation-icons-mobile">
            <Icon
              icon="arrowRotate"
              width="28"
              height="23"
              containerSize="28"
              clickable="false"
            />
            {isTablet ? (
              <Icon
                icon="tablet"
                width="42"
                height="30"
                containerSize="43"
                color="#1C1B1F"
                clickable="false"
              />
            ) : (
              <Icon
                icon="phone"
                width="43"
                height="27"
                containerSize="43"
                color="#1C1B1F"
                clickable="false"
              />
            )}
            <Icon
              icon="arrowRotate"
              width="28"
              height="23"
              containerSize="28"
              clickable="false"
            />
          </div>
        }
        title="Gira el dispositivo para disfrutar de una mejor experiencia"
        buttons={
          <Button
            appearance="filled"
            color="dark-green"
            text="Aceptar"
            onClick={() => {
              toggleDisclaimer(false);
            }}
          />
        }
      />
      {!location.pathname.includes("/login") &&
      !location.pathname.includes("/signup") &&
      !location.pathname.includes("/models/") &&
      !location.pathname.includes("/modelsThree") &&
      !location.pathname.includes("/modelsViewer") &&
      !location.pathname.includes("/create_password") &&
      !location.pathname.includes("/summary") &&
      !location.pathname.includes("/success") ? (
        <Header username={user.nickname ? user.nickname : "user"} />
      ) : (
        <></>
      )}
      <Routes>
        <Route index path="/login/:id/:emailToken" element={<Login />} />
        <Route index path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/create_password" element={<Password />} />
        <Route element={<ProtectedRoute token={token} />}>
          <Route path="/" element={<Home />} />

          <Route path="/modelsThree/" element={<Three />} />
          <Route
            path="/modelsThree/:configId/:setColumnsId"
            element={<Three />}
          />
          <Route path="/modelsViewer/" element={<ThreeModelViewer />} />
          <Route
            path="/modelsViewer/:configId/:setColumnsId"
            element={<ThreeModelViewer />}
          />
          <Route path="/instructions" element={<Instructions />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/projects/:archived" element={<Projects />} />
          <Route path="/projects" element={<Projects />} />
          <Route
            path="/projects/:id/configurations"
            element={<Configurations />}
          />
          <Route path="/set-of-columns" element={<SetOfColumns />} />
          <Route path="/form" element={<FormView />} />
          <Route path="/check" element={<CheckView />} />
          <Route path="/summary" element={<Summary />} />
          <Route path="/success" element={<Success />} />
          <Route path="/error" element={<Error />} />

          <Route
            path="/:type/:subtype/models/:id/:config/:element"
            element={
              <ErrorBoundary fallback={<p>Something went wrong</p>}>
                <ModelViewer />
              </ErrorBoundary>
            }
          />

          <Route
            exact
            path="/:type/:subtype/models/:id"
            element={
              <ErrorBoundary fallback={<p>Something went wrong</p>}>
                <ModelViewer />
              </ErrorBoundary>
            }
          />

          <Route
            path="/:type/models/:id/:config/:element"
            element={<ModelViewer />}
          />
          <Route exact path="/:type/models/:id" element={<ModelViewer />} />
          <Route
            path="/:id/:name/subcategories/:count"
            element={<SubCategories />}
          />
          <Route
            path="/:id/:name/subcategories/:count/columns"
            element={<Columns />}
          />
          <Route
            path="/:id/:name/subcategories/:count/trays"
            element={<Trays />}
          />
          <Route path="/:id/:name/subcategories" element={<SubCategories />} />
          <Route path="*" element={<Home />} />
        </Route>
      </Routes>
      {!location.pathname.includes("/models/") &&
        !location.pathname.includes("/modelsViewer") &&
        !location.pathname.includes("/modelsThree") && <Footer year="2024" />}
    </div>
  );
}

export default App;
