import { useState, useEffect, useCallback } from "react";
import Button from "../components/Button.js";
import Icon from "../components/Icon.js";

const Hotspot = ({
  className,
  label,
  modalName,
  label_position,
  position,
  position_mobile,
  position_modal,
  position_modal_mobile,
  data,
  active,
  onClick,
  filterError,
  toogleFilterError,
}) => {
  const [opened, toggleModal] = useState(false);
  const [selectedFeature, setFeature] = useState(null);
  const [error, toogleError] = useState(false);

  function updateModal() {
    toggleModal(!opened);
    if (opened) {
      toogleError(false);
      toogleFilterError(false);
    }
  }

  function updateFeature() {
    let response = onClick(selectedFeature.id);

    if (response == undefined) {
      toogleError(false);
      toogleFilterError(false);
      updateModal();
    } else {
      toogleError(true);
      toogleFilterError(true);
    }
  }

  function selectFeature(feature) {
    setFeature(feature);
    document.getElementById(selectedFeature.id).classList.remove("active");
    document.getElementById(selectedFeature.id).classList.add("inactive");
    document.getElementById(feature.id).classList.remove("inactive");
    document.getElementById(feature.id).classList.add("active");
  }

  function detectMob() {
    return window.screen.width <= 480;
    // return window.innerWidth <= 800 && window.innerHeight <= 600;
  }

  function showData() {
    return data.map((object) => {
      return (
        <div
          className={`filter ${active === object.id ? "active" : "inactive"}`}
          key={object.id}
          id={object.id}
          onClick={() => selectFeature(object)}
        >
          <div className="img-container">
            <img src={object.picture} />
          </div>
          <p>{object.name}</p>
        </div>
      );
    });
  }

  const onClickESC = (event) => {
    if (event.key === "Escape") {
      toggleModal(false);
      toogleError(false);
      toogleFilterError(false);
    }
  };

  useEffect(() => {
    setFeature(data.find((obj) => obj.id === active));

    document.addEventListener("keydown", onClickESC, false);

    return () => {
      document.removeEventListener("keydown", onClickESC, false);
    };
  }, []);

  useEffect(() => {
    toogleError(filterError);
  }, [filterError]);

  // check if data .lenght > 1. si no, no sacar el hotspot
  return (
    <div
      className={`${className} ${label_position} ${label
        .toLowerCase()
        .replace(" ", "-")} ${modalName.toLowerCase()}`}
      style={
        detectMob()
          ? { top: position_mobile.top, left: position_mobile.left }
          : { top: position.top, left: position.left }
      }
    >
      <div className="point" onClick={updateModal}></div>
      <p onClick={updateModal}>{label}</p>
      {opened && (
        <div className="hotspot-modal">
          <div className="container">
            <div className="close">
              <Icon
                icon="close"
                width="12.7"
                height="12.7"
                color="#FFFFFF"
                onClick={() => {
                  updateModal();
                }}
              />
            </div>
            {error && (
              <div className="modal-error">
                <Icon
                  icon="alertCircle"
                  color="#E30613"
                  width="19"
                  height="20"
                />
                <p className="error-text">
                  Esta opción no es compatible con los acabados seleccionados
                </p>
              </div>
            )}
            <div className="content">{showData()}</div>
            <div className="button">
              <Button
                appearance="filled"
                width="80"
                color="dark-green"
                text="Aceptar"
                onClick={() => {
                  updateFeature();
                }}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Hotspot;
