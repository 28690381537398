import { AppContext } from "../AppContext.js";
import { useState, useRef, useEffect, useContext } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";

import Icon from "./Icon.js";
import Logo from "../assets/b-ar-logo.svg";

const menuItems = [
  { route: "/", title: "Home" },
  { route: "/projects", title: "Mis configuraciones" },
  { route: "/projects/archived", title: "Mis archivados" },
  { route: "/profile", title: "Mi cuenta" },
  { route: "/login", title: "Cerrar sesión" },
];

const Header = ({ username }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dropdownMenu = useRef(null);
  const [isMenuVisible, setMenuVisibility] = useState(false);
  const { setUser } = useContext(AppContext);

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + window.localStorage.getItem("token"),
  };

  useEffect(() => {
    if (
      location.pathname.includes("/projects") ||
      location.pathname.includes("/archives")
    ) {
      document.querySelector("header").style.background = "#FFFFFF";
      return;
    }
    document.querySelector("header").style.background = "#F7f7f7";
  }, [location.pathname]);

  //Hook that hides menu when the user clicks outside (except the click on menu icon)
  useEffect(() => {
    const handleClickOutsideMenu = (event) => {
      if (
        dropdownMenu.current &&
        !dropdownMenu.current.contains(event.target) &&
        event.target.id !== "arrowDropDown"
      ) {
        setMenuVisibility(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutsideMenu);
    return () => {
      document.removeEventListener("mousedown", handleClickOutsideMenu);
    };
  }, [dropdownMenu]);

  const toggleMenu = () => {
    setMenuVisibility(!isMenuVisible);
  };

  const signOut = async () => {
    window.localStorage.removeItem("token");
    await fetch(`${process.env.REACT_APP_BACKEND_ENDPOINT}/api/users/logout`, {
      method: "POST",
      headers: headers,
      body: JSON.stringify({}),
    })
      .then(async (res) => {
        const data = await res.json();
        if (res.status === 401) navigate("/login");
        if (data.success) {
          toggleMenu();
          setUser({});
          navigate("/login");
        } else {
        }
      })
      .catch((error) => {
        console.error(error);
        navigate("/login");
      });
  };

  return (
    <header>
      <Link to="/">
        <img src={Logo} className="ar-logo" />
      </Link>
      <div className="header-menu">
        {username ? (
          <p className="hola-text">Hola {username}</p>
        ) : (
          <Link to="/login">
            <p className="hola-text">Iniciar sesión</p>
          </Link>
        )}
        <Icon icon="accountCircle" color="#00A5B5" />
        <Icon
          icon="arrowDropDown"
          containerSize="11"
          width="8"
          height="5"
          onClick={toggleMenu}
          color="#00A5B5"
        />
        <div
          ref={dropdownMenu}
          className="header-menu-content"
          style={{ visibility: isMenuVisible ? "visible" : "hidden" }}
        >
          {menuItems.map((item, i) => {
            return item.title === "Cerrar sesión" ? (
              <p onClick={signOut} key={i}>
                {item.title}
              </p>
            ) : (
              <Link to={item.route} onClick={toggleMenu} key={i}>
                {item.title}
              </Link>
            );
          })}
        </div>
      </div>
    </header>
  );
};

export default Header;
