import { useEffect, useCallback } from "react";

// "100vh" doesn't work properly on mobile devices. Thats why we set the height by javascript
// subtract header and footer heights if necessary
export function useContainerHeight({
  containerClassName,
  subHeader = false,
  subFooter = false,
}) {
  useEffect(() => {
    const e = {
      target: {
        innerHeight: window.innerHeight,
      },
    };
    setContainerElementsHeights(e);
    window.addEventListener("resize", setContainerElementsHeights);
    window.addEventListener("orientationchange", setContainerElementsHeights);

    return () => {
      window.removeEventListener("resize", setContainerElementsHeights);
      window.removeEventListener(
        "orientationchange",
        setContainerElementsHeights
      );
    };
  }, []);

  const setContainerElementsHeights = useCallback((e) => {
    const headerHeight = 72;
    const footerHeight = window.innerWidth < 640 ? 189 : 125;
    document.getElementsByClassName(containerClassName)[0].style.minHeight =
      e.target.innerHeight -
      (subHeader ? headerHeight : 0) -
      (subFooter ? footerHeight : 0) +
      "px";
  }, []);
}

//Close/hide an element when the user clicks outside
export function useClickOutside({ ref, className, onClickOutside }) {
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, onClickOutside]);

  const handleClickOutside = useCallback((event) => {
    if (
      ref.current &&
      ref.current.classList.contains(className) &&
      !ref.current.contains(event.target)
    ) {
      onClickOutside();
    }
  });
}
