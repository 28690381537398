import { useEffect, useState, useRef, forwardRef, useContext } from "react";
import { useNavigate } from "react-router-dom";

import Icon from "./Icon.js";
import Button from "./Button.js";
import Form from "./Form.js";
import Modal from "./Modal.js";
import ReactPaginate from "react-paginate";
import { AppContext } from "../AppContext.js";

const formFields = [
  {
    field: "textfield",
    id: "name",
    key: "name",
    label: "",
    value: "",
    type: "text",
    placeholder: "Nombre del proyecto",
  },
  {
    field: "textarea",
    id: "desc",
    key: "desc",
    label: "",
    value: "",
    type: "text",
    placeholder: "Descripción del proyecto (opcional)",
  },
];

const formButtons = [
  {
    type: "confirm",
    text: "Crear",
    appearance: "filled",
    color: "dark-green",
    width: 74,
  },
];

const ProjectOverlay = forwardRef(function ProjectOverlay(props, ref) {
  const { show, addConfiguration } = props;
  const createProjectModalRef = useRef(null);
  const navigate = useNavigate();
  const { user, sendProjectsReq } = useContext(AppContext);
  const [projects, setProjects] = useState({ data: [], total: 0 });
  const [createProjectModal, showCreateProjectModal] = useState(false);
  const [page, setPage] = useState(0);

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + window.localStorage.getItem("token"),
  };

  useEffect(() => {
    getProjects(0);
  }, []);

  useEffect(() => {
    document.querySelector("body").style.overflow =
      show || createProjectModal ? "hidden" : "auto";
  }, [show, createProjectModal]);

  //Close the create project modal when the user clicks outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        createProjectModalRef.current &&
        createProjectModalRef.current.classList.contains("active") &&
        !createProjectModalRef.current.contains(event.target)
      ) {
        showCreateProjectModal(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [createProjectModalRef]);

  const getProjects = (page) => {
    if (Object.keys(user).length !== 0) {
      sendProjectsReq(1, page)
        .then((data) => {
          setProjects({ data: data.body, total: data.total });
        })
        .catch((err) => {
          setProjects({ data: [], total: 0 });
          console.log(err);
        });
    }
  };

  const createProject = async (values) => {
    fetch(`${process.env.REACT_APP_BACKEND_ENDPOINT}/api/project/`, {
      method: "POST",
      headers: headers,
      body: JSON.stringify({
        name: values.name,
        description: values.desc,
        userId: user.id,
      }),
    })
      .then(async (res) => {
        const data = await res.json();
        if (data.status === 200) {
          getProjects(0);
        }
      })
      .catch((error) => {
        console.error(error);
        navigate("/login");
      });

    showCreateProjectModal(false);
  };

  const onChangeForm = (inputType, value) => {
    if (inputType === "name") {
      return value.length > 0
        ? { status: "OK", message: "OK" }
        : {
            status: "KO",
            message: "El nombre del proyecto no puede estar vacío.",
          };
    } else {
      return { status: "OK", message: "OK" };
    }
  };

  return (
    <main
      className={`project-overlay-wrapper ${show ? "open" : "closed"} ${
        createProjectModal && "overlay"
      }`}
      ref={ref}
    >
      <section className={`project-overlay-container`}>
        <h3 className="project-title">Añadir elemento al proyecto</h3>
        <div className="projects-container">
          {projects.data.length > 0 &&
            projects.data.map((project) => {
              return (
                <div
                  key={project.id}
                  className="project-box"
                  onClick={() => addConfiguration(project.id)}
                >
                  <div className="project-name-container">
                    <h4 className="project-name"> {project.name}</h4>
                  </div>
                  <p className="project-configurations">
                    {project.configurations} elementos
                  </p>
                </div>
              );
            })}
        </div>
        <ReactPaginate
          previousLabel={
            <Icon
              icon="chevronLeft"
              containerSize="20"
              width="5"
              height="9"
              color="#006227"
            />
          }
          nextLabel={
            <Icon
              icon="chevronRight"
              containerSize="20"
              width="5"
              height="9"
              color="#006227"
            />
          }
          pageClassName="page-item"
          previousClassName="page-item"
          nextClassName="page-item"
          breakLabel="..."
          breakClassName="page-item"
          pageCount={projects.total <= 8 ? 0 : Math.ceil(projects.total / 8)}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          forcePage={page}
          onPageChange={(e) => {
            setPage(e.selected);
            getProjects(e.selected);
          }}
          containerClassName="pagination-container"
          activeClassName="active"
          renderOnZeroPageCount={null}
        />
        <Button
          className="acceptConfigButton"
          appearance="stroke"
          color="dark-green"
          text="Crear nuevo proyecto"
          width="auto"
          iconLeft=<Icon icon="addCircle" width="20" height="20" />
          onClick={() => {
            showCreateProjectModal(true);
          }}
        />
      </section>
      <div className="create-project-modal">
        <Modal
          show={createProjectModal}
          title="Crear proyecto"
          elements={
            <Form
              formFields={formFields}
              formButtons={formButtons}
              onSubmit={createProject}
              onChange={onChangeForm}
            />
          }
          ref={createProjectModalRef}
        />
      </div>
    </main>
  );
});

export default ProjectOverlay;
