import Button from "./Button.js";
import Icon from "./Icon.js";
import Hotspot from "./Hotspot.js";

const HotspotConfiguration = ({
  config,
  data_map,
  selected_map,
  selectedType,
  updateModel,
  filterError,
  toogleFilterError,
  hideConfig,
}) => {
  let hotspots = config.filters.map((hotspot, i, filters) => {
    if (
      data_map[hotspot].length === 0 ||
      !data_map[hotspot].some((element) => element.active)
    )
      return null;
    return (
      <Hotspot
        key={`${hotspot}_i`}
        className="hotspot"
        modalName={selectedType?.type_name}
        label={config.names[hotspot]}
        label_position={hotspot.label_position}
        position={config[`position_${hotspot}`]}
        position_mobile={config[`position_mobile_${hotspot}`]}
        position_modal={{ top: "1rem", left: "-1.9rem" }}
        position_modal_mobile={{ top: "-50vh", left: "5vw" }}
        data={data_map[hotspot]}
        active={selected_map[hotspot]}
        onClick={(value) => {
          return updateModel(`${hotspot}_id`, value, 0);
        }}
        filterError={filterError}
        toogleFilterError={(e) => {
          toogleFilterError(e);
        }}
      />
    );
  });
  hotspots.push(
    <Button
      className="acceptConfigButton"
      appearance="filled"
      color="dark-green"
      text="OK"
      onClick={() => {
        hideConfig();
      }}
      iconRight={<Icon icon="check" color="#FFFFFF" width="15" height="12" />}
    />,
  );

  return hotspots;
};

export default HotspotConfiguration;
