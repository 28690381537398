import Logo from "../assets/b-ar-logo.svg";
import { Link } from "react-router-dom";

const Footer = ({ year }) => {
  return (
    <footer>
      <div className="footer">
        <div className="footer-promo">
          <p className="footer-title">Aumenta la realidad</p>
          <p className="footer-subtitle">de tu propuesta</p>
        </div>
        <div className="links">
          <Link to="/instructions">
            <p className="footer-text">Instrucciones de RA</p>
          </Link>
          <Link to="/">
            <img src={Logo} className="ar-logo" />
          </Link>
        </div>
      </div>
      <div className="footer-legal">
        <p className="footer-text">
          © HEINEKEN España {year}. HEINEKEN recomienda el consumo responsable
          de todas sus marcas
        </p>
      </div>
    </footer>
  );
};

export default Footer;
