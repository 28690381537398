import { useEffect, useState, useRef } from "react";

import Slider from "react-slick";
import Icon from "./Icon.js";
import { tempGalleryImages } from "../assets/constants.js";

const Gallery = ({ images, mainImg, close }) => {
  const [arrowsDisabled, setArrows] = useState(false);
  const [mainImgIndex, setMainImgIndex] = useState(0);
  const slider = useRef(null);

  const sliderSettings = {
    infinite: true,
    speed: 400,
    slidesToShow: images.length > 2 ? 3 : 1,
    className: "gallery-slider",
    centerMode: true,
    arrows: false,
    draggable: false,
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
    beforeChange: (current, next) => setMainImgIndex(next),
  };

  useEffect(() => {
    if (images.length === 0) {
      images = tempGalleryImages;
    } else if (images.length === 1) {
      setArrows(true);
    }
    setMainImgIndex(mainImg);
    slider.current.slickGoTo(mainImg, true);

    document.addEventListener("keydown", onClickESC, false);

    return () => {
      document.removeEventListener("keydown", onClickESC, false);
    };
  }, []);

  const disableArrows = () => {
    setArrows(true);
    setTimeout(() => {
      setArrows(false);
    }, 200);
  };

  const onClickESC = (event) => {
    if (event.key === "Escape") {
      close();
    }
  };

  return (
    <div className="gallery">
      <div className="gallery-content">
        <div className="gallery-close">
          <Icon
            icon="close"
            width="12.7"
            height="12.7"
            onClick={close}
            color="#FFFFFF"
          />
        </div>
        <div className="images">
          <Slider {...sliderSettings} ref={slider}>
            {images.map((img, i) => {
              return (
                <div className="img-container" key={i}>
                  <img src={img} />
                </div>
              );
            })}
          </Slider>
        </div>
        <div
          className={`bottom-section ${images.length === 1 ? "disabled" : ""}`}
        >
          <div
            className="arrow"
            onClick={() => {
              slider.current.slickPrev();
              disableArrows();
            }}
          >
            <Icon
              icon="arrowBack"
              width="14.88"
              height="14.64"
              color="#FFFFFF"
              clickable={!arrowsDisabled}
            />
          </div>
          <div>
            {mainImgIndex + 1 + " / "}
            {images.length === 0 ? tempGalleryImages.length : images.length}
          </div>
          <div
            className="arrow"
            onClick={() => {
              slider.current.slickNext();
              disableArrows();
            }}
          >
            <Icon
              icon="arrowForward"
              width="14.88"
              height="14.64"
              color="#FFFFFF"
              clickable={!arrowsDisabled}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Gallery;
