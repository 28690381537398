const positionArray = [
  {
    type: "column",
    model: "Aguila",
    names: { texture: "Acabado", brand: "Marca", kind: "Tipología" },
    filters: ["texture", "brand", "kind"],
    position_kind: { top: "24vh", left: "54vw" },
    position_brand: { top: "35vh", left: "43vw" },
    position_texture: { top: "22vh", left: "45vw" },
    position_mobile_kind: { top: "25vh", left: "64vw" },
    position_mobile_brand: { top: "35vh", left: "25vw" },
    position_mobile_texture: { top: "20vh", left: "35vw" },
    label_position: "left",
  },
  {
    type: "column",
    model: "Arya",
    names: { texture: "Acabado", brand: "Marca", kind: "Tipología" },
    filters: ["texture", "brand", "kind"],
    position_kind: { top: "32vh", left: "55vw" },
    position_brand: { top: "35vh", left: "43vw" },
    position_texture: { top: "26vh", left: "45vw" },
    position_mobile_kind: { top: "32vh", left: "60vw" },
    position_mobile_brand: { top: "35vh", left: "24vw" },
    position_mobile_texture: { top: "20vh", left: "40vw" },
    label_position: "left",
  },
  {
    type: "column",
    model: "Arya-Aguila",
    names: { texture: "Acabado", brand: "Marca", kind: "Tipología" },
    filters: ["texture", "brand", "kind"],
    position_kind: { top: "32vh", left: "55vw" },
    position_brand: { top: "35vh", left: "43vw" },
    position_texture: { top: "26vh", left: "45vw" },
    position_mobile_kind: { top: "32vh", left: "60vw" },
    position_mobile_brand: { top: "35vh", left: "24vw" },
    position_mobile_texture: { top: "20vh", left: "40vw" },
    label_position: "left",
  },
  {
    type: "column",
    model: "Bone",
    names: { texture: "Acabado", brand: "Marca", kind: "Tipología" },
    filters: ["texture", "brand", "kind"],
    position_kind: { top: "25vh", left: "55vw" },
    position_brand: { top: "27vh", left: "44vw" },
    position_texture: { top: "40vh", left: "45vw" },
    position_mobile_kind: { top: "25vh", left: "55vw" },
    position_mobile_brand: { top: "27vh", left: "27vw" },
    position_mobile_texture: { top: "40vh", left: "62vw" },
    label_position: "right",
  },
  {
    type: "column",
    model: "Cruzcampo2019",
    names: { texture: "Acabado", brand: "Marca", kind: "Tipología" },
    filters: ["texture", "brand", "kind"],
    position_kind: { top: "32vh", left: "55vw" },
    position_brand: { top: "43vh", left: "43vw" },
    position_texture: { top: "26vh", left: "45vw" },
    position_mobile_kind: { top: "32vh", left: "65vw" },
    position_mobile_brand: { top: "43vh", left: "24vw" },
    position_mobile_texture: { top: "25vh", left: "33vw" },
    label_position: "left",
  },
  {
    type: "column",
    model: "Heitube",
    names: { texture: "Acabado", brand: "Marca", kind: "Tipología" },
    filters: ["texture", "brand", "kind"],
    position_kind: { top: "27vh", left: "54vw" },
    position_brand: { top: "30vh", left: "43vw" },
    position_texture: { top: "19vh", left: "47vw" },
    position_mobile_kind: { top: "25vh", left: "65vw" },
    position_mobile_brand: { top: "30vh", left: "22vw" },
    position_mobile_texture: { top: "20vh", left: "40vw" },
    label_position: "left",
  },
  {
    type: "column",
    model: "Iconic",
    names: { texture: "Acabado", brand: "Marca", kind: "Tipología" },
    priority: ["kind", "texture", "brand"],
    filters: ["texture", "brand", "kind"],
    position_kind: { top: "34vh", left: "54vw" },
    position_brand: { top: "38vh", left: "44vw" },
    position_texture: { top: "23vh", left: "47vw" },
    position_mobile_kind: { top: "32vh", left: "64vw" },
    position_mobile_brand: { top: "35vh", left: "27vw" },
    position_mobile_texture: { top: "20vh", left: "40vw" },
    label_position: "left",
  },
  {
    type: "Tray",
    model: "In",
    names: { kind: "N columnas" },
    filters: ["kind"],
    position_kind: { top: "70vh", left: "45vw" },
    position_mobile_kind: { top: "60vh", left: "40vw" },
    label_position: "left",
  },
  {
    type: "Tray",
    model: "Over",
    names: { kind: "N columnas" },
    filters: ["kind"],
    position_kind: { top: "70vh", left: "45vw" },
    position_mobile_kind: { top: "60vh", left: "40vw" },
    label_position: "left",
  },
  {
    type: "Orion",
    model: "Horizontal",
    names: {
      texture: "Material",
      brand: "Marca",
      kind: "Tamaño",
      texture2: "Material 2",
      kind2: "Acabado",
    },
    priority: ["kind2", "texture2", "kind", "texture", "brand"],
    filters: ["texture", "brand", "kind", "texture2", "kind2"],
    position_texture: { top: "43vh", left: "45vw" },
    position_brand: { top: "52vh", left: "30vw" },
    position_kind: { top: "75vh", left: "40vw" },
    position_texture2: { top: "32vh", left: "30vw" },
    position_kind2: { top: "55vh", left: "50vw" },
    position_mobile_texture: { top: "45vh", left: "30vw" },
    position_mobile_brand: { top: "55vh", left: "10vw" },
    position_mobile_kind: { top: "65vh", left: "25vw" },
    position_mobile_texture2: { top: "37vh", left: "10vw" },
    position_mobile_kind2: { top: "55vh", left: "40vw" },
    label_position: "left",
  },
  {
    type: "Orion",
    model: "Vertical",
    names: {
      texture: "Material",
      brand: "Marca",
      kind: "Tamaño",
      texture2: "Material 2",
    },
    filters: ["texture", "brand", "kind", "texture2"],
    position_texture: { top: "43vh", left: "47vw" },
    position_brand: { top: "21vh", left: "43vw" },
    position_kind: { top: "50vh", left: "42vw" },
    position_texture2: { top: "85vh", left: "45vw" },
    position_mobile_texture: { top: "43vh", left: "47%" },
    position_mobile_brand: { top: "20vh", left: "23vw" },
    position_mobile_kind: { top: "50vh", left: "25vw" },
    position_mobile_texture2: { top: "85vh", left: "35vw" },
    label_position: "left",
  },
  {
    type: "Cavas",
    model: "Bottle",
    names: {
      texture: "Material",
      brand: "Marca",
      kind: "N puertas",
      kind2: "Tipo",
    },
    filters: ["texture", "brand", "kind", "kind2"],
    position_texture: { top: "60vh", left: "33vw" },
    position_brand: { top: "40vh", left: "33vw" },
    position_kind: { top: "55vh", left: "45vw" },
    position_kind2: { top: "28vh", left: "50vw" },
    position_mobile_texture: { top: "56vh", left: "19vw" },
    position_mobile_brand: { top: "45vh", left: "18vw" },
    position_mobile_kind: { top: "50vh", left: "50vw" },
    position_mobile_kind2: { top: "40vh", left: "35vw" },
    label_position: "left",
  },
  {
    type: "Cavas",
    model: "Barrel",
    names: {
      texture: "Material",
      brand: "Marca",
      kind: "N puertas",
      kind2: "Tipo",
    },
    filters: ["texture", "brand", "kind", "kind2"],
    position_texture: { top: "60vh", left: "33vw" },
    position_brand: { top: "40vh", left: "33vw" },
    position_kind: { top: "55vh", left: "45vw" },
    position_kind2: { top: "28vh", left: "50vw" },
    position_mobile_texture: { top: "56vh", left: "19vw" },
    position_mobile_brand: { top: "45vh", left: "18vw" },
    position_mobile_kind: { top: "50vh", left: "50vw" },
    position_mobile_kind2: { top: "40vh", left: "35vw" },
    label_position: "left",
  },
  {
    type: "David",
    model: "David",
    names: {
      brand: "Marca",
    },
    filters: ["brand"],
    position_brand: { top: "60vh", left: "40%" },
    position_mobile_brand: { top: "45vh", left: "18%" },
    label_position: "left",
  },
  {
    type: "David",
    model: "DavidXL",
    names: {
      brand: "Marca",
    },
    filters: ["brand"],
    position_brand: { top: "55vh", left: "40%" },
    position_mobile_brand: { top: "52vh", left: "20%" },
    label_position: "left",
  },
  {
    type: "Nevera",
    model: "Cabinet",
    names: {
      brand: "Marca",
      kind: "Tamaño",
    },
    filters: ["brand", "kind"],
    position_brand: { top: "55vh", left: "40%" },
    position_kind: { top: "55vh", left: "54vw" },
    position_mobile_brand: { top: "52vh", left: "20%" },
    position_mobile_kind: { top: "50vh", left: "50vw" },
    label_position: "left",
  },
];

//not being used anymore, pngs can be deleted in further development
const tempGalleryImages = [
  "/assets/gallery-img-1.png",
  "/assets/gallery-img-2.png",
  "/assets/columnas.png",
  "/assets/heitube.png",
  "/assets/ar_hand_prompt.png",
  "/assets/ar_icon.png",
  "/assets/cavas.png",
];

const properties = [
  "brand_id",
  "texture_id",
  "kind_id",
  "texture2_id",
  "kind2_id",
  "subtype_id",
];

exports.properties = properties;
exports.positionArray = positionArray;
exports.tempGalleryImages = tempGalleryImages;
