import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Icon from "./Icon.js";
import Button from "../components/Button.js";
import ar_img from "../assets/ar.png";

const ArPopUp = ({ show, className, close }) => {
  console.log(show);
  const navigate = useNavigate();

  const handleCookie = () => {
    window.localStorage.setItem(
      "arpopup",
      document.getElementsByTagName("input")[0].checked.toString(),
    );
  };

  return (
    <div
      className={`modal-dialog ${show ? "active" : "not-active"} ${className}`}
    >
      <div className="content">
        <div className="question-mark-1">
          <img src={ar_img} />
        </div>
        <p className="title">Activa la Función de Realidad Aumentada (AR)</p>

        <div className="subtitle">
          <p>
            Para disfrutar al máximo de esta experiencia, asegúrate de activar
            la función de Realidad Aumentada (AR) en tu navegador. Sin esta
            función habilitada, lamentablemente no podrás visualizar los modelos
            3D.
          </p>
        </div>
        <div className="checkbox">
          <label className="container">
            <span>
              <input type="checkbox" />
            </span>
            <span className="cbox-text">
              No mostrar este mensaje nuevamente
            </span>
          </label>
          <p className="checkbox-subtext">
            Al marcar esta casilla, confirmas que no deseas que este mensaje
            vuelva a aparecer en futuras visitas. Recuerda que puedes acceder a
            las instrucciones desde el pie de página.
          </p>
        </div>
        <div className="modal-ar-buttons">
          <Button
            appearance="stroke"
            color="dark-green"
            text="Ver instrucciones"
            width="auto"
            className="back-btn"
            iconRight={
              <Icon icon="description" width="15" height="20" color="#006227" />
            }
            onClick={() => {
              handleCookie();
              navigate("/instructions");
            }}
          />
          <Button
            appearance="filled"
            color="dark-green"
            text="Continuar"
            width="auto"
            className="back-btn"
            iconRight={
              <Icon
                icon="arrowForward"
                width="15"
                height="15"
                color="#FFFFFF"
              />
            }
            onClick={() => {
              handleCookie();
              close();
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ArPopUp;
