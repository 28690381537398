import { useState, createContext } from "react";
import { useNavigate } from "react-router-dom";

import SetColumnsImg from "./assets/set-columns-summary.png";

export const AppContext = createContext();

export const AppProvider = (props) => {
  const [user, setUser] = useState({});
  const [idSetColumns, updateIdSetColumns] = useState({});
  const [columnsConfig, setColumnsConfig] = useState({
    columns: [],
    trays: [],
  });

  // const [columnsConfig, setColumnsConfig] = useState({
  //   columns: [
  //     {
  //       alias: "HEITUBE",
  //       id: 5,
  //       model:
  //         "/assets/models_opt/column/heitube/COLUMN_HEITUBE_HEINEKEN_COPPER-MATTE_CFT.glb",
  //       name: "Heitube",
  //       order: 0,
  //       picture:
  //         "https://heinekentest.s3.eu-west-1.amazonaws.com/subtypes/columnas/heitube.png",
  //     },
  //     {
  //       alias: "ARYA",
  //       id: 2,
  //       model:
  //         "/assets/models_opt/column/arya/COLUMN_ARYA_HEINEKENSILVER_N_CFT.glb",
  //       name: "Arya",
  //       order: 1,
  //       picture:
  //         "https://heinekentest.s3.eu-west-1.amazonaws.com/subtypes/columnas/arya.png",
  //     },
  //     {
  //       alias: "ARYA-AGUILA",
  //       id: 18,
  //       model:
  //         "/assets/models_opt/column/arya-aguila/COLUMN_ARYA_AGUILADORADA_N_CFT.glb",
  //       name: "Arya-Aguila",
  //       order: 2,
  //       picture:
  //         "https://heinekentest.s3.eu-west-1.amazonaws.com/subtypes/columnas/arya-aguila.png",
  //     },
  //   ],
  //   trays: [
  //     {
  //       name: "Bandeja Triple",
  //       img: "/static/media/triple-tray.9c0688c0045edb2cdd6a.png",
  //       value: 3,
  //       id: 0,
  //       addMore: true,
  //     },
  //   ],
  // });

  const [newColumn, setNewColumn] = useState({});
  const [summary, setSummary] = useState({});
  const [galleryImages, setGalleryImages] = useState([]);
  const [modelViewerSRC, setModelViewerSRC] = useState(null);

  const navigate = useNavigate();

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + window.localStorage.getItem("token"),
  };

  const getUserInfo = async () => {
    try {
      let res = await fetch(
        `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/users/token`,
        {
          method: "GET",
          headers: headers,
        },
      );
      const data = await res.json();
      if (res.status === 401 || data.status === "UNAUTHORIZED") {
        navigate("/login");
      } else {
        setUser({
          email: data.body[0].email,
          nickname: data.body[0].nickname,
          id: data.body[0].id,
        });
      }
    } catch (error) {
      console.error(error);
      navigate("/login");
    }
  };

  // A common fetch function can be used
  const fetchRequest = async (url, method, body) => {
    return fetch(process.env.REACT_APP_BACKEND_ENDPOINT + url, {
      method: method,
      headers: headers,
      body: body ? JSON.stringify(body) : null,
    })
      .then(async (res) => {
        const data = await res.json();
        if (data.status === 200) {
          return data;
        } else {
          console.log("ERROR");
          navigate("/login");
        }
      })
      .catch((err) => {
        console.log(err);
        navigate("/login");
      });
  };

  //get elements based on type id
  const getElements = async (type) => {
    const res = await fetchRequest(`/api/elements/${type}/5/element`, "GET");
    return res.body.sort((a, b) => a.id - b.id);
  };

  const generateSetColumnsSummary = async (columns) => {
    setSummary({
      set_columnsId: null,
      img: SetColumnsImg,
      configs: [
        {
          label: "Producto",
          value: "Set de Columnas",
        },
        {
          label: "Cantidad",
          value: columns.columns.length,
        },
        {
          label: "Bandejas",
          value: columns.trays
            .reverse()
            .map((t) => {
              return t.value;
            })
            .join("/"),
        },
        {
          label: "Modelos",
          value: columns.columns
            .map((c) => {
              return c.name;
            })
            .join(", "),
        },
      ],
    });
  };

  const generateSummary = (element) => {
    setSummary({
      elementSettingsId: element.id,
      img: element.subtype_picture,
      configs: [
        {
          label: "Producto",
          value: element.type_name ? element.type_name : "N/A",
        },
        {
          label: "Modelo",
          value: element.subtype_name ? element.subtype_name : "N/A",
        },
        {
          label: "Marca",
          value: element.brand_name ? element.brand_name : "N/A",
        },
        {
          label: "Acabado",
          value:
            element.texture_name && element.texture2_name
              ? element.texture_name + " / " + element.texture2_name
              : element.texture_name
                ? element.texture_name
                : element.texture2_name
                  ? element.texture2_name
                  : "N/A",
        },
        {
          label: "Tipología",
          value:
            element.kind_name && element.kind2_name
              ? element.kind_name + " / " + element.kind2_name
              : element.kind_name
                ? element.kind_name
                : element.kind2_name
                  ? element.kind2_name
                  : "N/A",
        },
      ],
    });
  };

  const sendProjectsReq = async (active, page) => {
    return fetch(
      `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/project/${active}/${page}`,
      {
        method: "GET",
        headers: headers,
      },
    )
      .then(async (res) => {
        const data = await res.json();
        if (data.status === 200) {
          return data;
        }
      })
      .catch((error) => {
        console.log("error");
        return error;
      });
  };

  const getGalleryImages = async (subtypeId) => {
    await fetch(
      `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/images/element/${subtypeId}`,
      {
        method: "GET",
        headers: headers,
      },
    )
      .then(async (res) => {
        const data = await res.json();
        if (data.status === 200) {
          setGalleryImages(data.body.map((el) => el.picture));
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <AppContext.Provider
      value={{
        user,
        setUser,
        columnsConfig,
        setColumnsConfig,
        newColumn,
        setNewColumn,
        getUserInfo,
        getElements,
        summary,
        setSummary,
        generateSummary,
        generateSetColumnsSummary,
        sendProjectsReq,
        galleryImages,
        getGalleryImages,
        modelViewerSRC,
        setModelViewerSRC,
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};
